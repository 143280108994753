import { Slide, toast } from "react-toastify"
// import imageCompression from 'browser-image-compression';
import { OneSignalAppID, protocol, RestAPI, url } from "./Config";
import axios from 'axios'




export const PreventNonNumeric = (e) => {
  if (e.key === "e" || e.key === "+" || e.key === "-" || e.key === "." || e.key === "*") {
    e.preventDefault()
  }
}


export const restrictWhiteSpace = (e, value) => {
  if (!value) {
    if (e.key === " ") {
      e.preventDefault()
    }
  }

  if (value) {
    if ((e.key === " " && value.length === 0)) {
      e.preventDefault()
    }
  }
}

export const ShowFailedMessage = (message) => {
  toast.error(message, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
    transition: Slide,
    progress: false,
  });
}

export const ShowSuccessMessage = (message) => {
  toast.success(message, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
    transition: Slide,
    progress: false,
  });
}

export const ConvertException = (message) => {
  
  let text = message;
  const newMessage = text.split("'");
  // console.log(newMessage)
  // show = []
  for (let i = 0; i < newMessage.length; i++) {

    if (newMessage[i].includes("[ErrorDetail(string=")) {

      ShowFailedMessage(newMessage[i + 1])
    }
  }
}


// export const handleImageCompress = async (event, size, maxRes, compress) => {
//   console.log(event.target.files[0])

//   const imageFile = event.target.files[0];
//   console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true

//   var fileType = event.target.files[0].name.split(".")[1]
//   if (compress && fileType != "gif" && imageFile) {
//     console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
//     // console.log("file type : ",fileType)
//     const options = {
//       // fileType: fileType, 
//       // maxSizeMB: size,
//       maxWidthOrHeight: maxRes,
//       useWebWorker: true
//     }
//     try {
//       const compressedFile = await imageCompression(imageFile, options);
//       // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
//       // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

//       // console.log(compressedFile); // write your own logic
//       // console.log("compressedFile Blob : ",compressedFile)
//       // +'.'+fileType
//       var file = new File([compressedFile], compressedFile.name);
//       console.log("compressedFile File : ", file)

//       // return compressedFile
//       return file

//     } catch (error) {
//       console.log(error);
//     }
//   } else {
//     console.log("Not Compressing")
//     return imageFile
//   }
// }



export const addDomainInImage = (image) => {
  // console.log("addDomainInImage ",image)
  let formattedImage = ""
  if (image == "" || image == null) {
    let noimage = "https://static.thenounproject.com/png/2999524-200.png"
    // console.log("formatted Image ",)
    formattedImage = noimage
    return formattedImage
  }
  if (typeof (image) === "string") {
    if (image === "") {
      let noimage = "https://static.thenounproject.com/png/2999524-200.png"
      // console.log("formatted Image ",)
      formattedImage = noimage
    }
    else {

      let img = image.includes('http')
      if (img) {
        formattedImage = image.replace('http://', protocol)
      } else {
        formattedImage = url + image

      }
    }
    // console.log("formatted Image ", formattedImage)
    return formattedImage
  } else {
    if (image == "") {
      let noimage = "https://static.thenounproject.com/png/2999524-200.png"
      // console.log("formatted Image ",)
      formattedImage = noimage
      return formattedImage
    }
    // console.log("Cannot format type ",typeof(image))
  }

}

export const DateCustomFunction = (date_data, keyword) => {
  let res_date = date_data

  if (typeof (res_date === "string")) {
    let myDate = new Date(res_date);
    if (keyword === "increment") {
      let nextDayOfMonth = myDate.getDate() + 1;
      myDate.setDate(nextDayOfMonth);
      // res_date = myDate.toLocaleString();
      res_date = DateFormatter(myDate);
    }
    if (keyword === "decrement") {
      let nextDayOfMonth = myDate.getDate() - 1;
      myDate.setDate(nextDayOfMonth);
      // res_date = myDate.toLocaleString();
      res_date = DateFormatter(myDate);
    }
  }
  res_date = new Date(res_date)
  console.log("DateCustomFunction >>>>>>>>>>>>>>>>>> ", res_date)
  console.log("DateCustomFunction >>>>>>>>>>>>>>>>>> ", DateFormatter(res_date))
  console.log("DateCustomFunction >>>>>>>>>>>>>>>>>> ", typeof (res_date))
  // console.log("DateCustomFunction >>>>>>>>>>>>>>>>>> ",res_date.toString().slice(0, 10))

  return DateFormatter(res_date)
}

export const DateFormatter = (date_data) => {
  let date = date_data
  let now_year = date.getFullYear().toString()
  let now_month = (date.getMonth() + 1).toString().length < 2 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()
  let now_day = (date.getDate().toString()).length < 2 ? "0" + (date.getDate().toString()) : (date.getDate().toString())
  let today = now_year + "-" + now_month + "-" + now_day
  return today
}

// export const capitalizeFirstLetter = (string) => {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }

export const validateEmail = (email) => {
  console.log("email validation ", email)
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  console.log("email validation ", re.test(String(email).toLowerCase()))
  return re.test(String(email).toLowerCase());
}
export function lettersOnly(str) {
	return str?.replace(/[^a-zA-Z]/g," ");
   //or return str.match(/[a-z]/gi).join('');
   //or return str.replace(/[^a-z]/gi,""); 
}
// export const sendNotification = async (data, player_id, postImage) => {

//   // const handleNotification = () => {
//     let result = ManageNotifications('add', data, '')
//     result.then((res) => {
//       console.log(res)
//     })
//       .catch((err) => {
//         console.log(err)
//       })


//   console.log("Data ", data)
//   // let app_id = "062a2759-ea0d-4f57-9f31-98cd013b3eb7"
//   let REST_API_KEY = RestAPI;
//   let app_id = '072c139e-0615-4c33-a635-ac93f3298ea5'

//   // if (localStorage.getItem("onsignal_id")) {
//   //   app_id = localStorage.getItem("onsignal_id")
//   // } else {
//   //   let tokenObject = localStorage.getItem("savebox_token")
//   //   var config = {
//   //     method: 'get',
//   //     url: url + '/settings/?name=onesignal_APPID',
//   //     headers: {
//   //       'Authorization': tokenObject,
//   //     },
//   //   };

//   //   await axios(config)
//   //     .then(function (response) {
//   //       console.log((response));
//   //       if (response.data.results && response.data.results.length) {
//   //         localStorage.setItem("onsignal_id", response.data.results[0].value)
//   //         app_id = response.data.results[0].value;
//   //       }
//   //     })
//   //     .catch(function (error) {
//   //       console.log(error);
//   //     });
//   // }




//   console.log(app_id)

//   let new_data = {

//     "title": data.title,
//     "body": data.body,

//   }

//   let onsignal_data = {
//     app_id: app_id,

//     headings: { "en": new_data.title },
//     contents: { "en": new_data.body },

//     data: new_data,

//     include_player_ids: [player_id], // worked
//     // include_player_ids: ['3caa71b8-782e-11ec-b412-5a3960cf6da6'], 

//   };

//   // if (REST_API_KEY && !player_id) {
//   //   onsignal_data = {
//   //     app_id: app_id,
//   //     headings: { "en": new_data.title },
//   //     contents: { "en": new_data.body },
//   //     // subtitle: { "en": "SUBTITLE MESSAGE GENERATED ON " + JSON.stringify(new Date()) },// not visible
//   //     // url:"https://onesignal.com",
//   //     // name:"name", // worked no idea where it comes
//   //     included_segments: [
//   //       "Subscribed Users",
//   //       "Active Users",
//   //       // "Inactive Users"
//   //       // "Engaged Users"
//   //     ],
//   //     data: new_data,

//   //     // include_player_ids: [player_id], // worked
//   //     // include_player_ids: ['3caa71b8-782e-11ec-b412-5a3960cf6da6'], // worked
//   //     // "big_picture": addDomainInImage("/media/post/img.webp"),
//   //     // "big_picture": new_data.image_url,
//   //     // "buttons": [{ "id": "id2", "text": "YES", "icon": "https://img.icons8.com/material-outlined/2x/furniture-store.png" }, { "id": "id1", "text": "NO", "icon": "https://img.icons8.com/material-outlined/2x/furniture-store.png" }]

//   //     //   "filters" : [
//   //     //     // {"field": "last_session",  "relation": "<", "value": "2"}, 
//   //     //     // {"operator": "OR"}, 
//   //     //     // {"field": "session_time", "relation": ">", "value": "5"}
//   //     //   ] // working
//   //   };
//   //   // postImage =''
//   //   if (postImage) {
//   //     onsignal_data["big_picture"] = addDomainInImage(postImage)
//   //   }
//   // }


//   let headers = {
//     "Content-Type": "application/json"
//   }

//   if (REST_API_KEY) {
//     headers["Authorization"] = 'Basic ' + REST_API_KEY
//   }

//   var config = {
//     method: 'post',
//     port: 443,
//     url: 'https://onesignal.com/api/v1/notifications/',
//     headers: headers,
//     data: onsignal_data
//   };
//   console.log('config', config)
//   console.log("onsignal_data", onsignal_data)
//   console.log("rest api key", REST_API_KEY)

//   axios(config)
//     .then(function (response) {
//       // console.log(JSON.stringify(response));
//       console.log(response.data);
//       // ShowSuccessMessage("Notification sent")

//     })
//     .catch(function (error) {
//       // ShowFailedMessage('Failed to sent notification')
//       // console.log(error);
//       // console.log(typeof(error));
//       console.log(error)
//     });




// };

export const formatDate = (date, format) => {
  if (!date) {
    return "Not Available"
  }
  let inputDate = new Date(date)
  // console.log(date, inputDate)
  let day = inputDate.getDate();
  if (day.toString().length == 1) {
    day = "0" + day.toString()
  }
  let month = (inputDate.getMonth() + 1);
  if (month.toString().length == 1) {
    month = "0" + month.toString()
  }
  let year = inputDate.getFullYear();
  let hour = inputDate.getHours();
  if (hour.toString().length == 1) {
    hour = "0" + hour.toString()
  }
  let minute = inputDate.getMinutes();
  if (minute.toString().length == 1) {
    minute = "0" + minute.toString()
  }
  let second = inputDate.getSeconds();
  // console.log(day, month, year)
  if (format == "DD-MM-YYYY") {
    let formatted_date = day + "-" + month + "-" + year + " " + hour + ":" + minute
    return formatted_date;
  }
  if (format == "DD-MM-YY") {
    let formatted_date = day + "-" + month + "-" + year.toString().slice(2, 4)
    return formatted_date;
  }
  if (format == "YYYY-MM-DD") {
    let formatted_date = year + "-" + month + "-" + day + " " + hour + ":" + minute
    return formatted_date;
  }
}

// export const blankValidation = (item) => {
//   // console.log(item)

//   for (let i = 0; i < item.length; i++) {
//     console.log(item[i])
//     if (!item[i].data) {
//       ShowFailedMessage(item[i].name + ' cannot be empty')
//     }
//   }
// }



export const sortByKeyField = (data, keyField) => {
  return data.sort(function (a, b) {
    return a[keyField] - b[keyField];
  });
}