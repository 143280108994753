import { Delete, Description, Edit } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import React, { useState } from 'react'
import CustomModal from '../Modal/Modal'

const ExperienceCard = ({ position, type, company, startDate, endDate, isEditable, currentlyWorking, onEdit, onDelete, Description }) => {
    const [MOdalOpen, setMOdalOpen] = useState(false)
    return (
        <>
            <div className='flex justify-between items-center mt-5'>
                <div className='w-full sm:w-auto'>
                    <h2 className='font-semibold text-sm'>{position}</h2>
                    <div className='flex'>
                        <p className='font-medium text-xs '>{company}</p>
                        <p className='mx-1 text-sm'>-</p>
                        <span className='font-light text-xs '>{type.replace('_', ' ').toLowerCase()}</span>
                    </div>
                    <div>
                        <p className='font-light text-xs '>{Description}</p>
                    </div>

                </div>
                {(startDate || endDate) ?
                    <div className='flex w-72 justify-end sm:justify-between items-center'>

                        <div className='hidden sm:flex justify-start'>
                            <p className='text-sm'>{startDate}</p>
                            <p className='mx-1 text-sm'>-</p>
                            <p className='text-sm'>{endDate ? endDate : currentlyWorking && 'Present'}</p>
                        </div>

                        {isEditable &&
                            <div className="flex">
                                <IconButton aria-label="edit" size="medium" color='inherit' onClick={onEdit} >
                                    <Edit fontSize="inherit" />
                                </IconButton>
                                <IconButton aria-label="delete" size="medium" color='error' onClick={() => setMOdalOpen(true)}>
                                    <Delete fontSize="inherit" />
                                </IconButton>
                            </div>
                        }

                    </div>
                    :
                    null
                }
            </div>

            {MOdalOpen &&
                <CustomModal open={MOdalOpen} onClose={() => setMOdalOpen(false)} >
                    <div >
                        <p className='font-medium'>
                            Are you sure want to delete ?
                        </p>
                        <div className='flex justify-end mt-5'>
                            <Button color='inherit' onClick={() => setMOdalOpen(false)}>Cancel</Button>
                            <Button color='primary' variant='contained' onClick={onDelete}>Yes</Button>
                        </div>
                    </div>
                </CustomModal>
            }

        </>
    )
}

export default ExperienceCard