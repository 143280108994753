import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import GoogleIcon from '../../Assets/Google-icon.svg'
import { url } from '../../Functionalities/Config'
import { ShowFailedMessage, ShowSuccessMessage } from '../../Functionalities/Functions'
import { userValidation } from '../../Functionalities/Validation'

const Login = () => {
    const [loginDetails, setloginDetails] = useState({ username: '', password: '' })


    const handleLogin = (e) => {
        e.preventDefault()

        let validation = userValidation(loginDetails, ['username', 'password']);

        if (!validation) {
            return
        }


        var data = new FormData();
        data.append('username', loginDetails.username);
        data.append('password', loginDetails.password);

        var config = {
            method: 'post',
            url: url + '/user/login/',
            data: data
        };

        axios(config)
            .then(function (res) {
                console.log(res.data)
                if (res.data.Status) {
                    ShowSuccessMessage(res.data.Message)
                    localStorage.setItem('job-in-arabia-token', res.data.Data.token);
                    localStorage.setItem('job-in-arabia-token', res.data.Data.token);
                } else {
                    ShowFailedMessage(res.data.Message)

                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    return (
        <section className="hero-section flex justify-center items-start">
            <div className="grid py-7 w-11/12 sm:w-auto">
                <div className="text-center">
                    <h5 className="md:text-xl text-lg font-medium">Welcome back</h5>
                    <h1 className="md:text-2xl text-xl font-bold mt-2">Login to your account</h1>
                </div>
                <div className="grid mt-2">
                    <div className>
                        <label className="font-bold text-xs md:text-sm">Email </label>
                    </div>
                    <div className>
                        <input className="bg-white border px-2 border-black w-full py-1 rounded placeholder-black" type="text" placeholder="Enter Your username" value={loginDetails.username} onChange={(e) => setloginDetails({ ...loginDetails, username: e.target.value })} />
                    </div>
                </div>
                <div className="grid mt-2">
                    <div className>
                        <label className="font-bold text-xs md:text-sm">Password </label>
                    </div>
                    <div className>
                        <input className="bg-white border px-2 border-black w-full py-1 rounded placeholder-black" type="password" placeholder="Enter Your Password" value={loginDetails.password} onChange={(e) => setloginDetails({ ...loginDetails, password: e.target.value })} />
                    </div>
                </div>
                <div className="mt-3 flex justify-between">
                    <div>
                        <input type="checkbox" /><label htmlFor className="text-xs md:text-sm font-bold pl-2">Remember me</label>
                    </div>
                    <div><Link className="text-xs md:text-sm font-semibold" to="/login" style={{ color: 'rgb(44, 82, 130)' }}>Forgot password?</Link></div>
                </div>
                <div className="mt-3">
                    <button type="button" className="w-full login-btn text-white py-2 text-sm md:text-base" onClick={handleLogin}>Login now</button>
                </div>
                <div className="mt-3">
                    <h4 className="md:text-2xl text-xl font-bold grid justify-center text-center">
                        <span> Login using</span>
                        <span>Social Media accounts</span>
                    </h4>
                </div>
                <div className="mt-3">
                    <button className="btn-google text-white text-sm md:text-base font-medium w-full py-2 rounded flex justify-center" type="button">
                        <img src={GoogleIcon} alt="google icon" className="mr-3" />Continue with Google</button>
                    <button className="mt-5 btn-facebook text-white text-sm md:text-base font-medium w-full py-2 rounded" type="button"><i className="fab fa-facebook px-2 text-xl" aria-hidden="true" />Continue with Facebook</button>
                    <button className="mt-5 btn-linkedin text-white text-sm md:text-base font-medium w-full py-2 rounded" type="button"><i className="fab fa-linkedin-in " aria-hidden="true" />Login with Linkedin</button>
                </div>
                <div className="mt-3 flex justify-center mb-12">
                    <div className="font-bold text-sm md:text-base" style={{ color: 'rgb(98, 98, 98)' }}>Don't have an account?<Link to="/signup" style={{ color: 'rgb(43, 108, 176)' }}> Join free today</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login