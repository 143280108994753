import { Skeleton } from '@mui/material'
import React from 'react'
import Card from '../Cards/Card'

const JobCardLoader = () => {
    return (
        <Card>
            <div className='grid grid-cols-1 gap-3'>
                <div className='flex items-center'>
                    <Skeleton variant='circular' width={40} height={40} />
                    <div className='ml-2'>
                        <Skeleton variant='text' width={135} height={24} />
                        <Skeleton variant='text' width={105} height={16} />
                    </div>
                </div>

                <div>

                    <Skeleton variant='text' width={135} height={20} />
                    <Skeleton variant='text' width={'100%'} height={100} />
                </div>
                <div className='grid grid-cols-6 gap-1'>
                    <Skeleton variant='text' width={'100%'} height={24} />
                    <Skeleton variant='text' width={'100%'} height={24} />
                    <Skeleton variant='text' width={'100%'} height={24} />
                </div>
                <div>
                    <Skeleton variant='text' width={'100%'} height={2} />
                </div>
                <div className='flex justify-between items-center'>
                    <Skeleton variant='text' width={100} height={24} />
                    <Skeleton variant='text' width={64} height={30} />
                </div>

            </div>
        </Card>
    )
}

export default JobCardLoader