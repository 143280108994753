import styled from "@emotion/styled";
import { Add, ArrowBack, ArrowBackIos } from "@mui/icons-material";
import {
    Button,
    FormControl,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../Common/Context/Context";
import Card from "../../Components/Cards/Card";
import EducationCard from "../../Components/Cards/EducationCard";
import ExperienceCard from "../../Components/Cards/ExperienceCard";
import SKillCard from "../../Components/Cards/SkillCard";
import TextField from "../../Components/InputField/TextField";
import CustomModal from "../../Components/Modal/Modal";
import Overlay from "../../Components/Overlay/Overlay";
import {
    addDomainInImage,
    ShowFailedMessage,
    ShowSuccessMessage,
} from "../../Functionalities/Functions";
import { ManageAPIs } from "../../Functionalities/Logic";
import Profile from "../Profile/Profile";
import JobCards from "./JobCards";

import ReactScrollablePagination from "react-scrollable-pagination";
import { host, protocol } from "../../Functionalities/Config";
import { ManagePagination } from "../../Functionalities/PaginationLogic";
import { LoadingButton } from "@mui/lab";
import JobCardLoader from "../../Components/SkeltonLoader/JobCardLoader";
import BackButton from "../../Components/BackButton/BackButton";
import JobDetails from "./JobDetails";
import { Helmet } from "react-helmet";
// import {withrouter} from 'react-router-dom';

// inject history and location props
const Scroller = ReactScrollablePagination;

const Jobs = () => {
    const {
        IsSidemenuOpen,
        setToken,
        setUserData,
        Experience,
        Educations,
        UserData,
        Token,
        GetUserData,
    } = useContext(Context);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [data, setdata] = useState([]);
    const [Next, setNext] = useState("");
    const [Prev, setPrev] = useState("");
    const [MobileFilterDrawer, setMobileFilterDrawer] = useState(false);
    const [LogoutModal, setLogoutModal] = useState(false);
    const [postCount, setpostCount] = useState(0);
    const [SkillCount, setSkillCount] = useState(0);
    const [experienceLevel, setexperienceLevel] = useState(null);
    const [country, setcountry] = useState(null);
    const [jobtype, setjobtype] = useState(null);
    const [searchText, setsearchText] = useState("");
    const [paginationLoading, setpaginationLoading] = useState(false);
    const [InitialLoader, setInitialLoader] = useState(); // initial api loader
    const [skillCountNull, setskillCountNull] = useState(0);


    const [ProfileUpdateAlert, setProfileUpdateAlert] = useState(false)
    // useEffect(() => {
    //     !localStorage.getItem('job-in-arabia-token') && navigate('/signin')
    // }, [])

    useEffect(() => {
        console.log({ experienceLevel });
    }, [experienceLevel]);

    useEffect(() => {
        setSkillCount(UserData?.skill_count);
        let value = Token && !UserData?.skill_count ? true : false;
        setskillCountNull(value);
        // console.log({UserData})
        // console.log({ Token })
        // console.log({ SkillCount })
        // console.log({ value })
    }, [UserData]);

    useEffect(() => {
        if (MobileFilterDrawer) {
            document.body.style.overflow = "hidden";
        }
        return () => {
            document.body.style.overflow = "unset";
        };
    }, [MobileFilterDrawer]);

    useEffect(() => {
        getdata();
    }, [pathname]);

    useEffect(() => {
        if (UserData.country === null) {
            pathname == '/jobs-in-kuwait' && setProfileUpdateAlert(true)
        }
        console.log(Token)
    }, [UserData])


    useEffect(() => {
        Token && GetUserData();
    }, [Token]);

    // useEffect(() => {
    //     if (Next) {
    //         // console.log({ Next })
    //         window.addEventListener("scroll", handleScroll); // attaching scroll event listener
    //     }
    //     console.log('Next path ============>', Next)

    // }, [Next])

    useEffect(() => {
        if (experienceLevel) {
            if (experienceLevel == 0) {
                let param = `min_experience=0&max_experience=1`;
                getdata(param);
            } else if (experienceLevel == 1) {
                let param = `min_experience=1&max_experience=3`;
                getdata(param);
            } else if (experienceLevel == 2) {
                let param = `min_experience=3&max_experience=5`;
                getdata(param);
            } else if (experienceLevel == 3) {
                let param = `min_experience=5`;
                getdata(param);
            }
        } else {
            // getdata();
        }
    }, [experienceLevel]);

    useEffect(() => {
        if (country) {
            let param = `location=${country}`;
            getdata(param);
        } else {
            // getdata();
        }
    }, [country]);

    useEffect(() => {
        if (jobtype) {
            let param = `job_type=${jobtype}`;
            getdata(param);
        } else {
            // getdata();
        }
    }, [jobtype]);

    useEffect(() => {
        console.log(`next path (state) -----${Next}`);
    }, [Next]);

    const handleScroll = () => {
        let userScrollHeight =
            window.innerHeight + document.documentElement.scrollTop;
        let windowBottomHeight = document.documentElement.offsetHeight;
        // console.log(Math.round(userScrollHeight), windowBottomHeight)

        if (Math.round(userScrollHeight) === windowBottomHeight) {
            // console.log('api calling')
            handleNext();
        }
    };

    const getdata = (param) => {
        setInitialLoader(true);
        let path = "/jobpost/";
        let token = Token ? Token : ''
        let result = ManageAPIs("get", path, "", param ? param : "", token);
        result
            .then((res) => {
                console.log('job post res _____________>>', res);
                setInitialLoader(false)
                if (res.data) {
                    setpostCount(res.data.count);
                    setdata(res.data.results);
                    setNext(res.data.next);
                    setPrev(res.data.previous);
                } else {
                    setdata([]);
                    // setNext()
                    setPrev();
                }
            })

            .catch((err) => {
                console.log(err);
                setInitialLoader(false)
            });
    };

    const handleNext = () => {
        setpaginationLoading(true);

        let path = Next.replace("http:", protocol.replace("//", ""));
        let newPath = path.replace(protocol, "").replace(host, "");
        let token = Token ? Token : ''
        // console.log({ Next })
        console.log({ newPath });

        let result = ManageAPIs("get", newPath, "", "", token);

        result
            .then((res) => {
                console.log("pagination api response ____________", res);
                setpaginationLoading(false);
                if (res?.data) {
                    setdata([...data, ...res.data.results]);
                    setNext(res.data.next);
                    setPrev(res.data.previous);
                    setSkillCount(1);
                } else {
                    setdata([]);
                    // setNext()
                    setPrev();
                }
            })

            .catch((err) => {
                setpaginationLoading(false);
                console.log(err);
            });
    };
    const handlePrev = () => {
        let path = Next.replace("http:", protocol.replace("//", ""));

        let result = ManagePagination(path, "");
        result
            .then((res) => {
                console.log(res);
                if (res.data) {
                    setpostCount(res.data.count);
                    setdata(res.data.results);
                    setNext(res.data.next);
                    setPrev(res.data.previous);
                } else {
                    setdata([]);
                    // setNext()
                    setPrev();
                }
            })

            .catch((err) => {
                console.log(err);
            });
    };

    // function checkScrollDirection(event) {
    //     if (checkScrollDirectionIsUp(event)) {
    //         console.log('UP');
    //     } else {
    //         console.log('Down');
    //     }
    // }

    function checkScrollDirectionIsUp(event) {
        if (event.wheelDelta) {
            return event.wheelDelta > 0;
        }
        return event.deltaY < 0;
    }

    const CloseModal = () => {
        setLogoutModal(false);
    };

    const ApplyJob = (id) => {
        // return alert(id)
        let path = "/jobpost/jobrequest/";
        let token = Token ? Token : ''
        var formdata = new FormData();
        // formdata.append('user', '28');
        formdata.append("job", id);
        formdata.append("status", "0");

        let result = ManageAPIs("add", path, formdata, "", token);
        result
            .then((res) => {
                console.log(res);
                if (res.data.Status) {
                    ShowSuccessMessage(res.data.Message);
                    getdata();
                } else {
                    ShowFailedMessage(res.data.Message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const EmployeeType = [
        { value: "FULL_TIME", id: 0 },
        { value: "PART_TIME", id: 1 },
        // { value: 'SELF_EMPLOYED', id: 2 },
        { value: "FREELANCE", id: 3 },
        { value: "INTERNSHIP", id: 4 },
        { value: "TRAINEE", id: 5 },
        { value: "CONTRACT", id: 6 },
        // { value: 'TEMPORARY', id: 7 },
    ];

    // console.log({ Experience })
    const defaultImg =
        "https://icon-library.com/images/no-user-image-icon/no-user-image-icon-0.jpg";

    const handleLogout = () => {
        window.location.replace("/signin");
        localStorage.removeItem("job-in-arabia-userdetails");
        localStorage.removeItem("job-in-arabia-token");
        setUserData({});
        setToken("");
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };
    };

    // console.log({ SkillCount })

    const clearAllFilter = () => {
        setsearchText("");
        setcountry(null);
        setexperienceLevel(null);
        setjobtype(null);

        // document.querySelectorAll('input[type=radio]').forEach(el => el.checked = false);

        getdata();
    };

    return (
        <section className=" bg-slate-50 min-h-screen md:pt-10 px-3 sm:px-0 relative pb-16">
            <Helmet>
                <title>Jobs In Kuwait | Job In Arabia | Job Vacancies In Kuwait</title>
                <link rel="canonical" href="https://job-in-arabia.com/jobs-in-kuwait" />
                <meta name="description" content="job vacancy in kuwait find best jobs , find best job opportunity in kuwait" />
            </Helmet>
            {/* <main className={` m-auto grid ${Token ? 'lg:grid-cols-4 md:grid-cols-3' : 'lg:grid-cols-3 md:grid-cols-2'} grid-cols-1 md:gap-7 xl:px-16 py-0 container `}> */}

            {((pathname.includes("/profile")) || (pathname.includes("/job-details/"))) &&
                <div className=' m-auto grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 md:gap-7 xl:px-16 py-0 container '>
                    <BackButton />
                </div>
            }


            <CustomModal open={ProfileUpdateAlert} onClose={() => setProfileUpdateAlert(false)}>
                <div className="grid grid-cols-1 gap-5">
                    <div>
                        <p className="font-semibold text-base">Please update your contact number</p>
                    </div>

                    <div className="flex justify-end">
                        <Button color="inherit" onClick={() => setProfileUpdateAlert(false)}>
                            Remind me later
                        </Button>
                        <div className="px-2" />
                        <Button variant="contained" color="primary" onClick={() => { navigate('/career/profile'); setProfileUpdateAlert(false) }}>
                            Update Now
                        </Button>
                    </div>
                </div>
            </CustomModal>

            <main
                className={` m-auto grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 md:gap-7 xl:px-16 py-0 container `}
            >
                <div className={"hidden md:block sticky -top-44 h-fit md:pb-10"}>
                    <Card>
                        <div className="d-flex justify-center mb-2">
                            <img
                                src={
                                    UserData.image ? addDomainInImage(UserData.image) : defaultImg
                                }
                                className="rounded-full h-24 w-24 m-auto object-cover"
                                alt=""
                            />
                        </div>
                        {Token ? (
                            <>
                                <h1 className="font-bold text-center">
                                    {`${UserData.first_name ? UserData.first_name : ''}
                                     ${UserData.middle_name ? UserData.middle_name : ''} 
                                     ${UserData.last_name ? UserData.last_name : ''}`}</h1>
                                <p className="font-light text-center text-xs">
                                    {UserData.current_designation}
                                </p>
                                <button
                                    onClick={() => navigate("/career/profile")}
                                    className="bg-slate-100  w-full text-blue text-xs m-auto rounded-full py-2 font-semibold mt-2"
                                >
                                    View Profile
                                </button>
                                <button
                                    onClick={() => navigate("/career/jobs")}
                                    className="bg-slate-100  w-full text-blue text-xs m-auto rounded-full py-2 font-semibold mt-2"
                                >
                                    My Jobs
                                </button>
                            </>
                        ) : (
                            <button
                                onClick={() => navigate("/signin")}
                                className="bg-slate-100  w-full text-blue text-xs m-auto rounded-full py-2 font-semibold mt-2"
                            >
                                Sign in / Register
                            </button>
                        )}
                    </Card>

                    {Token && (
                        <>
                            <Card>
                                <div className="flex justify-between items-center">
                                    <h1 className="font-semibold text-sm text-blue-500">
                                        Work Experience
                                    </h1>

                                    {/* <i class="fas fa-plus" onClick={() => setLogoutModal(true)}></i> */}
                                    <IconButton
                                        color="inherit"
                                        onClick={() => navigate(`/career/profile`)}
                                    >
                                        <Add />
                                    </IconButton>
                                </div>

                                {Experience.map((item) => (
                                    <ExperienceCard
                                        position={item.title}
                                        company={item.company_name}
                                        type={
                                            EmployeeType.find(
                                                (itm) => itm.id == item.employement_type
                                            ).value
                                        }
                                    />
                                ))}
                            </Card>
                            <Card>
                                <div className="flex justify-between items-center">
                                    <h1 className="font-semibold text-sm text-blue-500">
                                        Education
                                    </h1>

                                    <IconButton
                                        color="inherit"
                                        onClick={() => navigate(`/career/profile`)}
                                    >
                                        <Add />
                                    </IconButton>
                                </div>

                                {Educations.map((item) => (
                                    <EducationCard level={item.degree} university={item.school} />
                                ))}
                            </Card>
                            {/* <Card>
                                <div className='flex justify-between items-center'>
                                    <h1 className='font-semibold text-sm text-blue-500'>Skills</h1>

                                    <IconButton color='inherit' onClick={() => navigate(`/profile`)}>
                                        <Add />
                                    </IconButton>

                                </div>

                                {Educations.map(item => (
                                    <SKillCard />
                                    
                                ))}




                            </Card> */}

                            <button
                                onClick={() => setLogoutModal(true)}
                                className="px-4 py-2  font-semibold text-sm text-sm bg-white w-full rounded-xl mt-4 shadow-md"
                            >
                                <i class="fas fa-sign-out-alt mr-2"></i>
                                Logout
                            </button>
                        </>
                    )}

                    {LogoutModal && (
                        <CustomModal open={LogoutModal} onClose={CloseModal}>
                            <div>
                                <p className=" font-medium">Are you sure want to logout ?</p>
                            </div>
                            <div className="flex justify-end mt-5">
                                <div className="flex">
                                    <Button color="inherit" onClick={() => setLogoutModal(false)}>
                                        Cancel
                                    </Button>
                                    <div className="px-1" />
                                    <Button variant="contained" onClick={handleLogout}>
                                        Yes
                                    </Button>
                                </div>
                            </div>
                        </CustomModal>
                    )}
                </div>



                {(pathname == "/jobs-in-kuwait" || pathname == "/jobs-in-kuwait/") && (
                    <>
                        <div className=" col-span-2  md:pb-10">
                            <Card>
                                <h1 className="font-bold text-lg capitalize ">search job</h1>
                                <div className={IsSidemenuOpen ? " mt-3" : "relative mt-3"}>
                                    <input
                                        type="text"
                                        placeholder="search..."
                                        onChange={(e) => setsearchText(e.target.value)}
                                        value={searchText}
                                        className="w-full bg-slate-100 p-2 rounded-md focus:outline-blue-500"
                                    />
                                    <button
                                        disabled={!searchText.length}
                                        className={
                                            IsSidemenuOpen
                                                ? "hidden"
                                                : "absolute inset-y-0 right-2 m-auto"
                                        }
                                        onClick={() => {
                                            searchText && getdata(`search_text=${searchText}`);
                                        }}
                                    >
                                        <i class="fas fa-search text-gray-500"></i>
                                    </button>
                                </div>
                            </Card>

                            <div
                                className={`flex lg:hidden flex-col items-center py-2  shadow-xl fixed w-full bg-white inset-x-0 bottom-0 transition-all duration-500 ease-in-out h-full items-start ${IsSidemenuOpen ? "hidden" : "z-40"
                                    }  ${MobileFilterDrawer
                                        ? "h-96 overflow-y-scroll overflow-x-hidden"
                                        : " h-12"
                                    }`}
                            >
                                <button
                                    className={`absolute -mt-10 ${!MobileFilterDrawer && "hidden"
                                        }`}
                                    onClick={() => setMobileFilterDrawer(false)}
                                >
                                    <i class="fas fa-times-circle text-gray-100"></i>
                                </button>

                                <button
                                    className=" px-3 text-sm  py-1 font-semibold mr-2 my-1 "
                                    onClick={() => setMobileFilterDrawer(true)}
                                >
                                    <i class="fas fa-list mr-2"></i>
                                    Filter
                                </button>

                                <div className=" lg:hidden block sticky -top-5 h-fit md:pb-10">
                                    {/* <Card>
                                        <div className="flex justify-between item-center">
                                            <h1 className='font-bold text-lg'>Job Filter</h1>
                                            
                                            <CustomButton size='small'  onClick={clearAllFilter} >clear all</CustomButton>
                                        </div>

                                    </Card> */}

                                    <div className="w-screen p-5 h-auto">
                                        <div className="flex justify-between item-center">
                                            <h1 className="font-semibold text-sm text-blue-500">
                                                Experience Level
                                            </h1>
                                            {/* <button className='text-blue-400 text-sm' >clear</button> */}
                                            <CustomButton
                                                size="small"
                                                onClick={() => {
                                                    setexperienceLevel(null);
                                                }}
                                            >
                                                clear
                                            </CustomButton>
                                        </div>

                                        <div className="mt-4" />
                                        <CustomFormControl>
                                            <CustomRadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={(e) => setexperienceLevel(e.target.value)}
                                                value={experienceLevel}
                                            >
                                                <CustomFormControlLabel
                                                    sx={CustomFormLabel}
                                                    value={0}
                                                    control={<Radio size="small" />}
                                                    label="Less than 1 year"
                                                    labelPlacement="start"
                                                />
                                                <CustomFormControlLabel
                                                    sx={CustomFormLabel}
                                                    value={1}
                                                    control={<Radio size="small" />}
                                                    label="1 - 3 year"
                                                    labelPlacement="start"
                                                />
                                                <CustomFormControlLabel
                                                    sx={CustomFormLabel}
                                                    value={2}
                                                    control={<Radio size="small" />}
                                                    label="3 - 5 year"
                                                    labelPlacement="start"
                                                />
                                                <CustomFormControlLabel
                                                    sx={CustomFormLabel}
                                                    value={3}
                                                    control={<Radio size="small" />}
                                                    label="Above 5 year"
                                                    labelPlacement="start"
                                                />
                                            </CustomRadioGroup>
                                        </CustomFormControl>
                                    </div>
                                    <div className="w-screen p-5 h-auto">
                                        <div className="flex justify-between items-center">
                                            <h1 className="font-semibold text-sm text-blue-500">
                                                Job Location
                                            </h1>
                                            {/* <button className='text-blue-400 text-sm' >clear</button> */}
                                            <CustomButton
                                                size="small"
                                                onClick={() => setcountry(null)}
                                            >
                                                clear
                                            </CustomButton>
                                        </div>

                                        <div className="mt-4" />
                                        <CustomFormControl>
                                            <CustomRadioGroup
                                                row
                                                onChange={(e) => {
                                                    setcountry(e.target.value);
                                                    console.log(e.target.value);
                                                }}
                                                value={country}
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <CustomFormControlLabel
                                                    sx={CustomFormLabel}
                                                    value="kuwait"
                                                    control={<Radio size="small" />}
                                                    label="Kuwait"
                                                    labelPlacement="start"
                                                />
                                                <CustomFormControlLabel
                                                    sx={CustomFormLabel}
                                                    value="india"
                                                    control={<Radio size="small" />}
                                                    label="India"
                                                    labelPlacement="start"
                                                />
                                            </CustomRadioGroup>
                                        </CustomFormControl>
                                    </div>
                                    <div className="w-screen p-5 h-auto">
                                        <div className="flex justify-between">
                                            <h1 className="font-semibold text-sm text-blue-500">
                                                Job Type
                                            </h1>
                                            {/* <button className='text-blue-400 text-sm' >clear</button>/ */}
                                            <CustomButton size="small" onClick={() => setjobtype("")}>
                                                clear
                                            </CustomButton>
                                        </div>
                                        <div className="mt-4" />
                                        <CustomFormControl>
                                            <CustomRadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={(e) => {
                                                    setjobtype(e.target.value);
                                                }}
                                                value={jobtype}
                                            >
                                                {EmployeeType.map((item) => (
                                                    <CustomFormControlLabel
                                                        sx={CustomFormLabel}
                                                        value={item.id}
                                                        control={<Radio size="small" />}
                                                        label={item.value
                                                            .replaceAll("_", " ")
                                                            .toLowerCase()}
                                                        labelPlacement="start"
                                                    />
                                                ))}
                                            </CustomRadioGroup>
                                        </CustomFormControl>
                                        {/* <div className="flex mt-1 justify-between items-center">
                                    <label htmlFor="age1" className='text-sm font-medium'>Full Time</label><br />
                                    <input type="radio" id="age1" name="age" defaultValue={30} />
                                </div>
                                <div className="flex mt-1 justify-between items-center">
                                    <label htmlFor="age1" className='text-sm font-medium'>Part Time</label><br />
                                    <input type="radio" id="age1" name="age" defaultValue={30} />
                                </div> */}
                                    </div>
                                </div>
                            </div>

                            {MobileFilterDrawer && (
                                <Overlay handleClick={() => setMobileFilterDrawer(false)} />
                            )}

                            {!InitialLoader && data.length ? (
                                data.map((item) => (
                                    <>
                                        <JobCards
                                            Position={item.job_name}
                                            Location={item.location}
                                            Id={item.id}
                                            Data={item}
                                            handleClick={ApplyJob}
                                        />

                                        {/* <button type='button' onClick={handleNext}>Next</button> */}
                                    </>
                                ))
                            )
                                :
                                (!InitialLoader && (
                                    <Card>
                                        <div className="text-center text-sm font-medium">
                                            {"No data found"}
                                        </div>
                                    </Card>
                                )
                                )}

                            {InitialLoader && <JobCardLoader />}
                            {/* <JobCards /> */}

                            {Next && (
                                <div className="flex justify-center py-5">
                                    <CustomLoadingButton
                                        onClick={handleNext}
                                        loading={paginationLoading}
                                        loadingIndicator="Loading..."
                                        color="primary"
                                        variant="contained"
                                    >
                                        Load More
                                    </CustomLoadingButton>
                                </div>
                            )}
                        </div>
                        <div className="hidden lg:block sticky -top-20 h-fit md:pb-10">
                            <Card>
                                <div className="flex justify-between item-center">
                                    <h1 className="font-bold text-lg">Job Filter</h1>
                                    {/* <Button size="small">clear all</Button> */}
                                    <CustomButton size="small" onClick={clearAllFilter}>
                                        clear all
                                    </CustomButton>
                                </div>
                            </Card>

                            <Card>
                                <div className="flex justify-between item-center">
                                    <h1 className="font-semibold text-sm text-blue-500">
                                        Experience Level
                                    </h1>
                                    {/* <button className='text-blue-400 text-sm' >clear</button> */}
                                    <CustomButton
                                        size="small"
                                        onClick={() => {
                                            setexperienceLevel(null);
                                            getdata()
                                        }}
                                    >
                                        clear
                                    </CustomButton>
                                </div>

                                <div className="mt-4" />
                                <CustomFormControl>
                                    <CustomRadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(e) => setexperienceLevel(e.target.value)}
                                        value={experienceLevel}
                                    >
                                        <CustomFormControlLabel
                                            sx={CustomFormLabel}
                                            value={0}
                                            control={<Radio size="small" />}
                                            label="Less than 1 year"
                                            labelPlacement="start"
                                        />
                                        <CustomFormControlLabel
                                            sx={CustomFormLabel}
                                            value={1}
                                            control={<Radio size="small" />}
                                            label="1 - 3 year"
                                            labelPlacement="start"
                                        />
                                        <CustomFormControlLabel
                                            sx={CustomFormLabel}
                                            value={2}
                                            control={<Radio size="small" />}
                                            label="3 - 5 year"
                                            labelPlacement="start"
                                        />
                                        <CustomFormControlLabel
                                            sx={CustomFormLabel}
                                            value={3}
                                            control={<Radio size="small" />}
                                            label="Above 5 year"
                                            labelPlacement="start"
                                        />
                                    </CustomRadioGroup>
                                </CustomFormControl>
                            </Card>
                            <Card>
                                <div className="flex justify-between items-center">
                                    <h1 className="font-semibold text-sm text-blue-500">
                                        Job Location
                                    </h1>
                                    {/* <button className='text-blue-400 text-sm' >clear</button> */}
                                    <CustomButton size="small" onClick={() => { setcountry(null); getdata() }}>
                                        clear
                                    </CustomButton>
                                </div>

                                <div className="mt-4" />
                                <CustomFormControl>
                                    <CustomRadioGroup
                                        row
                                        onChange={(e) => setcountry(e.target.value)}
                                        value={country}
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <CustomFormControlLabel
                                            sx={CustomFormLabel}
                                            value="kuwait"
                                            control={<Radio size="small" />}
                                            label="Kuwait"
                                            labelPlacement="start"
                                        />
                                        <CustomFormControlLabel
                                            sx={CustomFormLabel}
                                            value="india"
                                            control={<Radio size="small" />}
                                            label="India"
                                            labelPlacement="start"
                                        />
                                    </CustomRadioGroup>
                                </CustomFormControl>
                            </Card>
                            <Card>
                                <div className="flex justify-between">
                                    <h1 className="font-semibold text-sm text-blue-500">
                                        Job Type
                                    </h1>
                                    {/* <button className='text-blue-400 text-sm' >clear</button>/ */}
                                    <CustomButton size="small" onClick={() => { setjobtype(null); getdata() }}>
                                        clear
                                    </CustomButton>
                                </div>
                                <div className="mt-4" />
                                <CustomFormControl>
                                    <CustomRadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(e) => {
                                            setjobtype(e.target.value);
                                        }}
                                        value={jobtype}
                                    >
                                        {EmployeeType.map((item) => (
                                            <CustomFormControlLabel
                                                sx={CustomFormLabel}
                                                value={item.id}
                                                control={<Radio size="small" />}
                                                label={item.value.replaceAll("_", " ").toLowerCase()}
                                                labelPlacement="start"
                                            />
                                        ))}
                                    </CustomRadioGroup>
                                </CustomFormControl>
                                {/* <div className="flex mt-1 justify-between items-center">
                                    <label htmlFor="age1" className='text-sm font-medium'>Full Time</label><br />
                                    <input type="radio" id="age1" name="age" defaultValue={30} />
                                </div>
                                <div className="flex mt-1 justify-between items-center">
                                    <label htmlFor="age1" className='text-sm font-medium'>Part Time</label><br />
                                    <input type="radio" id="age1" name="age" defaultValue={30} />
                                </div> */}
                            </Card>
                        </div>
                    </>
                )}
                {pathname.includes("/career/profile") && (
                    <div className="lg:col-span-3 md:col-span-2">
                        <Profile />
                    </div>
                )}
                {pathname.includes("/job-details") &&
                    <div className="lg:col-span-3 md:col-span-2">
                        <JobDetails />
                    </div>
                }
            </main>
        </section>
    );
};

export default Jobs;
const CustomButton = styled(Button)(({ }) => ({
    textTransform: "capitalize",
    fontWeight: 500,
    fontSize: ".675rem",
    fontFamily: `'Raleway', 'sans-serif'`,
    padding: "0",
}));
const CustomLoadingButton = styled(LoadingButton)(({ }) => ({
    textTransform: "capitalize",
    fontWeight: 500,
    fontSize: "smaller",
    fontFamily: `'Raleway', 'sans-serif'`,
    // padding: '0'
}));

const CustomRadioGroup = styled(RadioGroup)(({ }) => ({
    width: "100%",
    justifyContent: "space-between",
    margin: "0",
}));
const CustomFormControlLabel = styled(FormControlLabel)(({ }) => ({
    width: "100%",
    justifyContent: "space-between",
    marginLeft: "0",
    textTransform: "capitalize",
    fontSize: ".75rem",
}));
const CustomFormControl = styled(FormControl)(({ }) => ({
    width: "100%",
}));

const CustomFormLabel = { ".MuiFormControlLabel-label": { fontSize: ".8rem" } };
