import styled from '@emotion/styled'
import { Add, Close, Download, Edit, FileUpload, PhotoCamera, Send } from '@mui/icons-material'
import { Button, Checkbox, Chip, Collapse, FormControl, FormControlLabel, IconButton, Input, Radio } from '@mui/material'
import { fontSize } from '@mui/system'
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../../Common/Context/Context'
import Card from '../../Components/Cards/Card'
import EducationCard from '../../Components/Cards/EducationCard'
import ExperienceCard from '../../Components/Cards/ExperienceCard'
import MultiSelect from '../../Components/InputField/MultiSelect'
import SelectField from '../../Components/InputField/SelectField'
import TextField from '../../Components/InputField/TextField'
import { url } from '../../Functionalities/Config'
import { addDomainInImage, ConvertException, lettersOnly, PreventNonNumeric, ShowFailedMessage, ShowSuccessMessage, validateEmail } from '../../Functionalities/Functions'
import { ManageAPIs } from '../../Functionalities/Logic'
import { userValidation } from '../../Functionalities/Validation'
import LoadingButton from '@mui/lab/LoadingButton';
import { Link, useLocation } from 'react-router-dom'


const Profile = () => {

    const { Experience, setExperience, Educations, setEducations, GetEducations, GetExperience, UserData, setUserData, UserID, GetUserData, Token } = useContext(Context)
    const defaultImg = 'https://icon-library.com/images/no-user-image-icon/no-user-image-icon-0.jpg'


    const [data, setdata] = useState({
        id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        date_of_birth: '', email: '',
        username: '',
        mobile: '',
        resume: '',
        current_designation: '',
        image: '',
        resume: '',
        password: '',
        confirm_password: '',
        country: ''
    })

    const [passwordEdit, setpasswordEdit] = useState(false)
    const [profileEdit, setprofileEdit] = useState(true)
    const [isEdit, setisEdit] = useState(false)
    const [EducationEdit, setEducationEdit] = useState(false)
    const [SkillsEdit, setSkillsEdit] = useState(false)
    const [skills, setskills] = useState([])
    const [SelectedSkills, setSelectedSkills] = useState([])
    const [searchSkills, setsearchSkills] = useState('')
    const [allSkills, setallSkills] = useState([])
    const [userSkills, setuserSkills] = useState([])
    const [InitialUserSkills, setInitialUserSkills] = useState([])
    const [experienceData, setexperienceData] = useState({
        id: '',
        title: '',
        company_name: '',
        employement_type: '',
        start_date: '',
        end_date: '',
        currently_working: false,
        description: ''
    })
    const [educationData, seteducationData] = useState({
        degree: "",
        description: "",
        end_date: "",
        grade: "",
        id: '',
        other_field: "",
        school: "",
        start_date: "",
        user: '',
    })


    const [ProfileBtnLoading, setProfileBtnLoading] = useState(false)

    let token = Token ? Token : ""

    useEffect(() => {
        if (token) {
            console.log({ token })
            GetAllSkills()
            GetUserSkills()
        }

    }, [token])

    useEffect(() => {
        if (UserData) {
            if (UserData.google_id) {
                setdata({
                    id: UserData.id,
                    first_name: UserData.first_name,
                    middle_name: UserData.middle_name,
                    last_name: UserData.last_name,
                    date_of_birth: UserData.date_of_birth,
                    email: UserData.email,
                    username: UserData.username,
                    mobile: UserData.mobile == UserData.google_id ? "" : UserData.mobile,
                    resume: UserData.resume,
                    current_designation: UserData.current_designation,
                    image: UserData.image,
                    resume: UserData.resume,
                    password: '',
                    confirm_password: '',
                })
            } else if (UserData.facebook_id) {
                setdata({
                    id: UserData.id,
                    first_name: UserData.first_name,
                    middle_name: UserData.middle_name,
                    last_name: UserData.last_name,
                    date_of_birth: UserData.date_of_birth,
                    email: UserData.email == UserData.facebook_id ? "" : UserData.email,
                    username: UserData.username,
                    mobile: UserData.mobile == UserData.facebook_id ? "" : UserData.mobile,
                    resume: UserData.resume,
                    current_designation: UserData.current_designation,
                    image: UserData.image,
                    resume: UserData.resume,
                    password: '',
                    confirm_password: '',
                })
            }
            else {
                setdata({
                    id: UserData.id,
                    first_name: UserData.first_name,
                    middle_name: UserData.middle_name,
                    last_name: UserData.last_name,
                    date_of_birth: UserData.date_of_birth,
                    email: UserData.email,
                    username: UserData.username,
                    mobile: UserData.mobile,
                    resume: UserData.resume,
                    current_designation: UserData.current_designation,
                    image: UserData.image,
                    resume: UserData.resume,
                    password: '',
                    confirm_password: '',
                })
            }
        }
    }, [UserData])

    console.log({ data })


    useEffect(() => {
        let arr = []
        if (SelectedSkills && token) {

            for (let i = 0; i < SelectedSkills.length; i++) {
                arr.push(SelectedSkills[i].id)
            }
            // console.log(SelectedSkills)
            GetAllSkills(`remove_list=${JSON.stringify(arr)}`)
            // GetSkills(JSON.stringify(SelectedSkills))
        }
    }, [token, SelectedSkills])

    useEffect(() => {
        token && GetUserData()
    }, [token])




    const GetAllSkills = (param) => {
        let path = '/skill/addskill/'

        let params = ''
        if (param) {
            params = param + '&pagination=0'
        } else {
            params = '&pagination=0'
        }
        let token = Token ? Token : ""
        let result = ManageAPIs('get', path, '', params, token)
        result.then((res) => {
            console.log('all skills', res)
            if (res.data) {
                if (param) {
                    setskills(res.data)

                } else {
                    setallSkills(res.data)

                }
            }
        })
            .catch((err) => {
                console.log(err)
            })

    }
    const GetUserSkills = () => {
        let path = '/skill/adduserskill/'
        let token = Token ? Token : ""
        let result = ManageAPIs('get', path, '', 'pagination=0', token)
        result.then((res) => {
            console.log('user skills', res)
            if (res.data) {
                setuserSkills(res.data)
                setUserData({ ...UserData, skill_count: res.data.length })
                localStorage.setItem('job-in-arabia-userdetails', JSON.stringify(UserData))
                let arr = []
                for (let i = 0; i < res.data.length; i++) {
                    arr.push(res.data[i].skill)
                }
                setInitialUserSkills(arr)
                setSelectedSkills(arr)

            }
        })
            .catch((err) => {
                console.log(err)
            })

    }
    const AddEducation = () => {
        let path = '/educationdetails/'
        let token = Token ? Token : ""

        var formdata = new FormData();
        formdata.append('school', educationData.school);
        formdata.append('degree', educationData.degree);
        formdata.append('grade', educationData.grade);
        formdata.append('start_date', educationData.start_date.slice(0, 10));
        formdata.append('end_date', educationData.end_date.slice(0, 10))
        formdata.append('description', educationData.description);

        let result = ManageAPIs('add', path, formdata, '', token)
        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                GetEducations()
                seteducationData({
                    degree: "",
                    description: "",
                    end_date: "",
                    grade: "",
                    id: '',
                    other_field: "",
                    school: "",
                    start_date: "",
                    user: '',
                })
            } else {
                ShowFailedMessage(res.data.Message)
            }
        })
            .catch((err) => {
                console.log(err)
            })


    }
    const EditEducation = () => {
        let path = '/educationdetails/'
        let token = Token ? Token : ""

        var formdata = new FormData();
        formdata.append('id', educationData.id);
        // formdata.append('user', UserID);
        formdata.append('school', educationData.school);
        formdata.append('degree', educationData.degree);
        formdata.append('grade', educationData.grade);
        formdata.append('start_date', educationData.start_date.slice(0, 10));
        formdata.append('end_date', educationData.end_date.slice(0, 10))
        formdata.append('description', educationData.description);

        let result = ManageAPIs('update', path, formdata, '', token)
        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                GetEducations()
                // setExperience([...Experience, res.data.Data])
            } else {
                ShowFailedMessage(res.data.Message)
            }
        })
            .catch((err) => {
                console.log(err)
            })


    }
    const AddExperience = () => {
        let path = '/experience/'


        var formdata = new FormData();
        // formdata.append('user', UserID);
        formdata.append('title', experienceData.title);
        formdata.append('employement_type', experienceData.employement_type);
        formdata.append('company_name', experienceData.company_name);
        formdata.append('start_date', experienceData.start_date.slice(0, 10));
        formdata.append('currently_working', experienceData.currently_working);
        !experienceData.currently_working && formdata.append('end_date', experienceData.end_date.slice(0, 10))
        formdata.append('description', experienceData.description);

        let result = ManageAPIs('add', path, formdata, '', token)
        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                GetExperience()
                setexperienceData({
                    id: '',
                    title: '',
                    company_name: '',
                    employement_type: '',
                    start_date: '',
                    end_date: '',
                    currently_working: false,
                    description: ''
                })
            } else {
                if (res.data.Message.includes('employement_type')) {
                    ShowFailedMessage(res.data.Message.replace('employement_type', 'Job Type'))
                } else {
                    ShowFailedMessage(res.data.Message.replaceAll('_', ' '))
                }
            }
        })
            .catch((err) => {
                console.log(err)
            })


    }
    const EditExperience = () => {
        let path = '/experience/'

        var formdata = new FormData();
        formdata.append('id', experienceData.id);
        formdata.append('user', UserID);
        formdata.append('title', experienceData.title);
        formdata.append('employement_type', experienceData.employement_type);
        formdata.append('company_name', experienceData.company_name);
        formdata.append('start_date', experienceData.start_date.slice(0, 10));
        formdata.append('currently_working', experienceData.currently_working);
        !experienceData.currently_working && formdata.append('end_date', experienceData.end_date.slice(0, 10))
        formdata.append('description', experienceData.description);

        let result = ManageAPIs('update', path, formdata, '', token)
        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                GetExperience()
            } else {
                ShowFailedMessage(res.data.Message)
            }
        })
            .catch((err) => {
                console.log(err)
            })


    }

    const DeleteExperience = (id) => {
        let path = '/experience/'
        let param = `ids=${JSON.stringify([id])}`

        let result = ManageAPIs('delete', path, '', param, token)
        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                // setExperience([...Experience, res.data.Data])
            } else {
                ShowFailedMessage(res.data.Message)
            }
        })
            .catch((err) => {
                console.log(err)
            })

    }
    const DeleteEducation = (id) => {
        let path = '/educationdetails/'
        let param = `ids=${JSON.stringify([id])}`

        let result = ManageAPIs('delete', path, '', param, token)
        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                setEducations(Educations.filter(itm => itm.id !== res.data.Data[0].id))
            } else {
                ShowFailedMessage(res.data.Message)
            }
        })
            .catch((err) => {
                console.log(err)
            })

    }

    const AddSkill = () => {


        if (allSkills.find(itm => itm.name.toLowerCase() == searchSkills.toLowerCase())) {
            ShowFailedMessage('alreday exist')
            return
        } else {

            let path = '/skill/addskill/'

            let formdata = new FormData();
            formdata.append('name', searchSkills);

            let result = ManageAPIs('add', path, formdata, '', token)

            result.then((res) => {
                console.log(res)
                if (res.data.Status) {
                    ShowSuccessMessage(res.data.Message)
                    setsearchSkills('')
                    GetAllSkills()
                    setskills([...skills, res.data.Data])
                    // setskills()
                } else {
                    ShowFailedMessage(res.data.Message)

                }
            })

                .catch((err) => {
                    console.log(err)
                })
        }
    }
    const addUserSkills = (id) => {
        // return console.log(id)
        let path = '/skill/adduserskill/'

        let formdata = new FormData();
        formdata.append('skill', JSON.stringify([id]));

        let result = ManageAPIs('add', path, formdata, '', token)

        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                GetUserSkills()
                setsearchSkills('')
            } else {
                ShowFailedMessage(res.data.Message)

            }
        })

            .catch((err) => {
                console.log(err)
            })


            .catch((err) => {
                console.log(err)
            })


    }

    const handleClick = (id) => {
        if (!SelectedSkills.includes(id)) {
            setSelectedSkills([...SelectedSkills, id])
        } else {
            setSelectedSkills(SelectedSkills.filter(itm => itm !== id))
        }
    }
    const HandleRemove = (id) => {

        console.log(userSkills.find(itm => itm.skill.id == id).id)
        // return
        // return alert(id)
        // return console.log(id)
        let newId = userSkills.find(itm => itm.skill.id == id).id
        let param = `ids=${JSON.stringify([newId])}`

        let path = '/skill/adduserskill/'

        let result = ManageAPIs('delete', path, '', param, token)
        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                GetUserSkills()
            } else {
                ShowFailedMessage(res.data.Message)
            }
        })
            .catch((err) => {

            })
    }

    const handleChange = (e) => {
        if ([e.target.type] == 'radio') {
            setdata({ ...data, [e.target.name]: e.target.id })

        } else {

            setdata({ ...data, [e.target.name]: e.target.value })
        }
        console.log(data)
    }

    const UpdateProfile = () => {
        setProfileBtnLoading(true)
        let ValidatingData = ['current_designation', 'username', 'first_name', 'last_name', 'email', 'mobile', 'date_of_birth']

        let validation = userValidation(data, ValidatingData);
        // return console.log('email---', data.email)
        let emailValidation = validateEmail(data.email)
        if (!emailValidation) {
            ShowFailedMessage('Please enter a valid email')
            setProfileBtnLoading(false)
            return
        }

        if (!validation) {
            setProfileBtnLoading(false)
            return
        }

        // console.log({ passwordEdit })
        // return
        if (passwordEdit) {
            let validation = userValidation(data, ['password', 'confirm_password'])

            if (!validation) {
                setProfileBtnLoading(false)
                return
            }
            if (data.password !== data.confirm_password) {
                ShowFailedMessage('passwords doest not match')

                setProfileBtnLoading(false)
                return
            }
        }


        // return console.log(typeof (data.image))


        var formData = new FormData();
        formData.append('id', data.id)
        formData.append('first_name', data.first_name)
        formData.append('last_name', data.last_name)
        formData.append('middle_name', data.middle_name ? data.middle_name : '')
        formData.append('username', data.username)
        data.password && formData.append('password', data.password)
        formData.append('email', data.email)
        formData.append('mobile', data.mobile)
        formData.append('country', data.country)
        data.date_of_birth && formData.append('date_of_birth', data.date_of_birth.slice(0, 10))
        data.current_designation && formData.append('current_designation', data.current_designation)
        if (data.image !== null && typeof (data.image) == 'object') {
            formData.append('image', data.image)
        } if (data.resume !== null && typeof (data.resume) == 'object') {
            formData.append('resume', data.resume)
        }

        let path = '/user/register/'
        let result = ManageAPIs('update', path, formData, '', token)
        result.then(function (res) {
            console.log(res.data)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                localStorage.setItem('job-in-arabia-userdetails', JSON.stringify(res.data.Data));
                setUserData(res.data.Data)
                setProfileBtnLoading(false)
                setpasswordEdit(false)
            } else if (res.data.Message.includes('Error occured')) {
                ConvertException(res.data.Message)
                setProfileBtnLoading(false)
            }
            else {
                ShowFailedMessage(res.data.Message)
                setProfileBtnLoading(false)
            }

        })
            .catch(function (error) {
                console.log(error);
                setProfileBtnLoading(false)
            });

    }
    // console.log('resume', typeof(data.resume))
    console.log({ userSkills })
    return (
        <div className='mb-40'>
            {/* profile start */}


            <Card >
                <div className="flex justify-between">
                    <h1 className='font-bold text-lg flex items-center' >Personal Information</h1>
                    <IconButton color='inherit' onClick={() => {
                        setprofileEdit(!profileEdit);
                        setpasswordEdit(false)
                    }}>

                        {profileEdit ?
                            <Close />
                            :
                            <Edit />
                        }
                    </IconButton>
                </div>
                <div className="lg:grid flex flex-col flex-col-reverse lg:grid-cols-3 " id={'profile'}>
                    <div className='mt-10 grid lg:col-span-2 grid-cols-1 sm:gap-6 md:gap-3 lg:grid-cols-2 gap-3 lg:gap-6'>

                        <TextField onchange={(e) => setdata({ ...data, current_designation: lettersOnly(e.target.value) })} value={data.current_designation} name={'current_designation'} isRequired={true} type={'text'} label={'Current Designation'} disabled={!profileEdit} />
                        <TextField onchange={handleChange} value={lettersOnly(data.first_name)} name={'first_name'} isRequired={true} type={'text'} label={'first name'} disabled={!profileEdit} />
                        <TextField onchange={handleChange} value={lettersOnly(data.middle_name)} name={'middle_name'} type={'text'} label={'middle name'} disabled={!profileEdit} />
                        <TextField onchange={handleChange} value={lettersOnly(data.last_name)} name={'last_name'} isRequired={true} type={'text'} label={'last name'} disabled={!profileEdit} />
                        {/* <TextField onchange={handleChange} value={data.username} name={'username'} isRequired={true} type={'text'} label={'username'} disabled={true} /> */}
                        <TextField onchange={handleChange} value={data.email} name={'email'} isRequired={true} type={'email'} label={'email'} disabled={!profileEdit} />
                        {/* <TextField onchange={handleChange} value={data.mobile} name={'mobile'} onKeyDown={PreventNonNumeric} isRequired={true} type={'number'} label={'mobile'} disabled={!profileEdit} /> */}
                        <TextField isRequired={true} onchange={(phone, e) => setdata({ ...data, mobile: phone, country: e.name })} value={data.mobile} name={'mobile'} disabled={!profileEdit} onKeyDown={PreventNonNumeric} type={'number'} label={'mobile'} />

                        <TextField onchange={handleChange} value={data.date_of_birth} name={'date_of_birth'} type={'date'} isRequired={true} label={'Date Of Birth'} disabled={!profileEdit} />

                        {passwordEdit &&
                            <>
                                <TextField onchange={handleChange} value={data.password} name={'password'} isRequired={true} type={'text'} label={'password'} disabled={!profileEdit} />
                                <TextField onchange={handleChange} value={data.confirm_password} name={'confirm_password'} type={'text'} label={'confirm password'} disabled={!profileEdit} />
                            </>

                        }
                        {/* <TextField type={'password'} label={'password'} /> */}
                    </div>




                    <div className={`flex flex-col items-center ${profileEdit ? 'justify-start' : 'justify-start'}  mt-10 lg:mt-0`}>
                        <img src={data.image ? typeof (data.image) == 'object' ? URL.createObjectURL(data.image) : addDomainInImage(data.image) : defaultImg} className={'w-40 p-2 rounded-full object-cover h-40 border border-2 border-gray-200'} alt="" />
                        {/* <input type="file" className='hidden' onChange={e => setdata({ ...data, image: e.target.files[0] })} name="" id="profile_pic" /> */}
                        {profileEdit &&
                            <>
                                <div className='mt-5'>

                                    <label htmlFor="contained-button-file">
                                        <Input accept="image/*" style={{ display: 'none' }} id="contained-button-file" type="file" onChange={e => setdata({ ...data, image: e.target.files[0] })} />
                                        <Button variant="contained" component="span" size='small' startIcon={<PhotoCamera />}>
                                            {data.image ? 'Change' : 'Upload'}
                                        </Button>
                                    </label>
                                </div>
                                {/* <button className='mt-5 bg-blue-500 text-white text-sm font-bold px-3 rounded py-2 shadow-md' onClick={() => document.querySelector(`#profile_pic`).click()} >Upload Image</button> */}
                                <div className='mt-5'>

                                    {data.resume?.name ?
                                        <Chip
                                            label={data.resume.name.slice(0, 10)}
                                            onClick={() => window.open(URL.createObjectURL(data.resume), '_blank')}
                                            onDelete={() => setdata({ ...data, resume: '' })}
                                        />

                                        :
                                        <label htmlFor="contained-button-resume">
                                            <Input accept="image/*" style={{ display: 'none' }} id="contained-button-resume" onChange={e => setdata({ ...data, resume: e.target.files[0] })} type="file" />

                                            <div className='flex ' style={{ flexDirection: 'column' }}>
                                                <Button variant="contained" size='small' component="span" startIcon={<FileUpload />}>
                                                    {data.resume ? `Change Resume` : `Upload Resume`}
                                                </Button>
                                                {data.resume &&

                                                    <a href={data.resume} download className='d-flex justify-contents-center'>
                                                        <CustomButton fullWidth color='info' size='small' >download resume</CustomButton>
                                                    </a>


                                                }
                                            </div>
                                        </label>
                                    }
                                </div>
                            </>
                        }

                        {/* {!profileEdit && data.resume &&
                            <div className='mt-10'>
                                <Button variant="contained" size='small' color='inherit' target={'_blank'} download={true} href={addDomainInImage(data.resume)} endIcon={<Download />}>
                                    Resume
                                </Button>
                            </div>
                        } */}

                    </div>
                </div>

                <div className='flex justify-between mt-10'>
                    {profileEdit &&
                        <>

                            <div>
                                {!UserData.google_id &&
                                    <CustomButton color='info' size='small' onClick={() => setpasswordEdit(!passwordEdit)}>{passwordEdit ? 'Cancel' : 'change password'}</CustomButton>
                                }
                            </div>
                            <LoadingButton
                                size="small"
                                onClick={UpdateProfile}
                                endIcon={<Send />}
                                loading={ProfileBtnLoading}
                                loadingPosition="end"
                                variant="contained"
                            >Submit</LoadingButton>

                        </>
                    }
                </div>



            </Card >
            {/* profile end */}

            {/* experience start*/}
            <Card>
                <>
                    <div className="" id='experience'>
                        <div className='flex justify-between'>
                            <h1 className='font-bold text-base flex items-center'>Work Experience</h1>

                            <IconButton color='inherit' onClick={() => {
                                setisEdit(!isEdit);
                                setexperienceData({
                                    ...experienceData,
                                    id: '',
                                    title: '',
                                    company_name: '',
                                    employement_type: '',
                                    start_date: '',
                                    end_date: '',
                                    currently_working: false,
                                    description: ''
                                })
                            }}>
                                {isEdit ?
                                    <Close />
                                    :
                                    <Add />
                                }
                            </IconButton>

                        </div>
                        <div>
                            {!isEdit ?
                                Experience.length ? Experience.map(item => (
                                    <ExperienceCard
                                        position={item.title}
                                        type={EmployeeType.find(itm => itm.id == item.employement_type).value.replace('_', ' ').toLowerCase()}
                                        startDate={item.start_date}
                                        endDate={item.end_date}
                                        company={item.company_name}
                                        isEditable={true}
                                        currentlyWorking={item.currently_working}
                                        Description={item.description}
                                        onEdit={() => {
                                            setisEdit(true)
                                            setexperienceData(item);
                                        }}
                                        onDelete={() => DeleteExperience(item.id)}
                                    />

                                ))
                                    :
                                    null
                                :

                                <div className='mt-10 grid col-span-2 sm:grid-cols-1 sm:gap-6 md:gap-3 md:grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-6 items-center'>
                                    <div className='grid  lg:col-span-1 col-span-2'>
                                        <TextField isRequired={true} type={'text'} label={'Title'} onchange={(e) => setexperienceData({ ...experienceData, title: e.target.value })} value={experienceData.title} />
                                    </div>
                                    <div className='grid  lg:col-span-1 col-span-2'>
                                        <TextField isRequired={true} type={'text'} label={'Company'} onchange={(e) => setexperienceData({ ...experienceData, company_name: e.target.value })} value={experienceData.company_name} />
                                    </div>

                                    <div className='grid  lg:col-span-1 col-span-2'>
                                        <SelectField isRequired={true} options={EmployeeType} label={'Job type'} placeholder={'Choose job type'} onchange={(e) => setexperienceData({ ...experienceData, employement_type: e.target.value })} value={experienceData.employement_type} />
                                    </div>

                                    <div className='grid  lg:col-span-1 col-span-2'>
                                        <TextField isRequired={true} type={'date'} label={'Start date'} onchange={(e) => setexperienceData({ ...experienceData, start_date: e.target.value })} value={experienceData.start_date} />
                                    </div>
                                    <div className='grid  lg:col-span-1 col-span-2'>
                                        <FormControl>
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="I'm currently work here" onChange={(e) => setexperienceData({ ...experienceData, currently_working: e.target.checked })} checked={experienceData.currently_working} />
                                        </FormControl>
                                    </div>

                                    <div className='grid  lg:col-span-1 col-span-2'>
                                        {!experienceData.currently_working &&
                                            <TextField isRequired={experienceData.currently_working ? false : true} type={'date'} label={'End date'} disabled={experienceData.currently_working} onchange={(e) => setexperienceData({ ...experienceData, end_date: e.target.value })} value={experienceData.end_date} />
                                        }
                                    </div>

                                    <div className='grid col-span-2'>

                                        <TextField isRequired={true} type={'textarea'} label={'Description'} rows={3} cols={3} onchange={(e) => setexperienceData({ ...experienceData, description: e.target.value })} value={experienceData.description} />
                                    </div>

                                    <div />

                                    <div className='flex justify-end'>
                                        {/* <Button variant='contained' color='inherit' onClick={() => setisEdit(false)}>Cancel</Button> */}
                                        <Button variant='contained' color='primary' onClick={() => { experienceData.id ? EditExperience() : AddExperience() }} size='small'>Submit</Button>
                                    </div>
                                </div>

                            }
                        </div>

                        {/* <h1 className='font-bold text-lg'></h1> */}
                        {/* <div className="grid grid-cols-3">
                        <div className='mt-5 grid col-span-3 sm:grid-cols-2 sm:gap-6 md:gap-3 md:grid-cols-1 lg:grid-cols-1 gap-3 lg:gap-6'>



                        </div>
                    </div> */}
                    </div>
                </>
            </Card>
            {/* experience end*/}

            {/* education start*/}
            <Card>
                <div className="" id='education'>
                    <div className='flex justify-between'>
                        <h1 className='font-bold text-base flex items-center'>Education</h1>

                        <IconButton color='inherit' onClick={() => {
                            setEducationEdit(!EducationEdit);
                            seteducationData({
                                ...educationData,
                                degree: "",
                                description: "",
                                end_date: "",
                                grade: "",
                                id: '',
                                other_field: "",
                                school: "",
                                start_date: "",
                                user: '',
                            })
                        }} >
                            {EducationEdit ?
                                <Close />
                                :
                                <Add />
                            }
                        </IconButton>

                    </div>
                    <div>
                        {!EducationEdit ?
                            Educations.length ? Educations.map(item => (
                                <EducationCard
                                    level={item.degree}
                                    university={item.school}
                                    grade={item.grade}
                                    position={item.title}
                                    startDate={item.start_date}
                                    endDate={item.end_date}
                                    isEditable={true}
                                    Description={item.description}
                                    onEdit={() => {
                                        setEducationEdit(true)
                                        seteducationData(item);
                                    }}
                                    onDelete={() => DeleteEducation(item.id)}
                                />
                            ))
                                :
                                null
                            :
                            <div className='mt-10 grid col-span-2 sm:grid-cols-1 sm:gap-6 md:gap-3 md:grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-6 items-center'>
                                <div className='grid lg:grid col-span-2'>
                                    <TextField isRequired={true} type={'text'} label={'School'} onchange={(e) => seteducationData({ ...educationData, school: e.target.value })} value={educationData.school} />
                                </div>
                                <div className='grid  lg:col-span-1 col-span-2'>
                                    <TextField isRequired={true} type={'text'} label={'Degree'} onchange={(e) => seteducationData({ ...educationData, degree: e.target.value })} value={educationData.degree} />
                                </div>
                                <div className='grid  lg:col-span-1 col-span-2'>
                                    <TextField type={'number'} label={'Percentage'} onchange={(e) => seteducationData({ ...educationData, grade: e.target.value })} value={educationData.grade} />
                                </div>
                                <div className='grid  lg:col-span-1 col-span-2'>
                                    <TextField isRequired={true} type={'date'} label={'Start date'} onchange={(e) => seteducationData({ ...educationData, start_date: e.target.value })} value={educationData.start_date} />
                                </div>
                                {/* <FormControl>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="I'm currently work here" onChange={(e) => seteducationData({ ...educationData, currently_working: e.target.checked })} checked={educationData.currently_working} />
                                </FormControl> */}
                                <div className='grid  lg:col-span-1 col-span-2'>
                                    <TextField isRequired={true} type={'date'} label={'End date'} disabled={educationData.currently_working} onchange={(e) => seteducationData({ ...educationData, end_date: e.target.value })} value={educationData.end_date} />
                                </div>
                                <div className='grid col-span-2'>
                                    <TextField  type={'textarea'} label={'Description'} rows={3} cols={3} onchange={(e) => seteducationData({ ...educationData, description: e.target.value })} value={educationData.description} />
                                </div>

                                <div />

                                <div className='flex justify-end'>
                                    {/* <Button variant='contained' color='inherit' onClick={() => setisEdit(false)}>Cancel</Button> */}
                                    <Button variant='contained' color='primary' onClick={() => { educationData.id ? EditEducation() : AddEducation() }} size='small'>Submit</Button>
                                </div>
                            </div>
                        }
                    </div>

                    {/* <h1 className='font-bold text-lg'></h1> */}
                    {/* <div className="grid grid-cols-3">
                        <div className='mt-5 grid col-span-3 sm:grid-cols-2 sm:gap-6 md:gap-3 md:grid-cols-1 lg:grid-cols-1 gap-3 lg:gap-6'>



                        </div>
                    </div> */}
                </div>
            </Card>
            {/* education end*/}

            {/* skills start*/}
            <Card>
                <div className="" id='experience'>
                    <div className='flex justify-between'>
                        <h1 className='font-bold text-base flex items-center'>Skills</h1>
                        <IconButton color='inherit' onClick={() => setSkillsEdit(!SkillsEdit)}>
                            {SkillsEdit ?
                                <Close />
                                :
                                userSkills.length ?
                                    <Edit />
                                    :
                                    <Add />
                            }
                        </IconButton>

                    </div>

                    {!SkillsEdit ?
                        !!userSkills.length &&
                        <div className='flex flex-wrap py-2'>
                            {userSkills.map(item => (
                                <div className='bg-slate-100 sm:px-3 px-1 text-xs outline outline-slate-400 outline-2 rounded py-1 font-semibold mr-2 my-1 cursor-default'>{item.skill.name}
                                    {/* <button type='button ' className='ml-2'><i class="fas fa-times text-xs"></i></button> */}
                                </div>
                            ))}
                        </div>
                        :
                        <>
                            <MultiSelect
                                allList={allSkills}
                                arrayList={skills}
                                userSkill={userSkills}
                                onSelect={addUserSkills}
                                removeSkill={HandleRemove}
                                SkillSelected={SelectedSkills}
                                Search={(e) => setsearchSkills(e.target.value)}
                                SearchValue={searchSkills}
                                handleAddSkill={AddSkill} />

                            {/* <div className='flex justify-end mt-2'>
                                    <Button color='primary' variant='contained' size='small' onClick={addUserSkills}>Submit</Button>
                                </div> */}
                        </>
                    }



                    {/* <h1 className='font-bold text-lg'></h1> */}
                    {/* <div className="grid grid-cols-3">
                        <div className='mt-5 grid col-span-3 sm:grid-cols-2 sm:gap-6 md:gap-3 md:grid-cols-1 lg:grid-cols-1 gap-3 lg:gap-6'>



                        </div>
                    </div> */}
                </div>
            </Card>
            {/* skills start*/}
        </div >
    )
}

export default Profile
const CustomButton = styled(Button)(({ }) => ({
    textTransform: 'lowercase',
    fontWeight: '400',
    fontSize: '.75rem'
}))

export const EmployeeType = [
    { value: 'FULL_TIME', id: 0 },
    { value: 'PART_TIME', id: 1 },
    { value: 'SELF_EMPLOYED', id: 2 },
    { value: 'FREELANCE', id: 3 },
    { value: 'INTERNSHIP', id: 4 },
    { value: 'TRAINEE', id: 5 },
    { value: 'CONTRACT', id: 6 },
    { value: 'TEMPORARY', id: 7 },
]