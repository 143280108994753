import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import SectionButton from '../../Components/SectionButton/SectionButton'
import TextReveal from '../../Components/TextAnimation/TextReveal'
import Slide from 'react-reveal/Slide';

const Services = () => {
    const { pathname } = useLocation()

    return (
        <section className='py-10 md:pb-24 md:pt-0 lg:py-28'>
            <div className='grid grid-cols-1 gap-5 md:flex flex-col md:flex-row  justify-between md:items-center'>
                <div>
                    {pathname !== '/service' &&
                        <SectionButton ButtonTitle={'Services'} />
                    }
                    {/* <h2 className='font-black text-2xl my-4'>Our Service</h2> */}
                    <TextReveal tag={'h2'} tagClassName={'font-black text-2xl my-4'} content={'Our Service'} />
                    <Slide bottom>
                        <p className='text-sm text-gray-500 font-semibold md:w-96 '>
                            We serve with the creed to revolutionize the workforce! Conquer and dominate your market leadership with our customised HR solutions that exemplify your time to lead the industry. Rise to the Top by making your work worth it…!
                        </p>
                    </Slide>
                </div>
                <div>

                    {/* {pathname !== '/service' &&
                        <Link to={'/service'} className='border-violet-500 border-2 px-4 py-1 rounded text-violet-500 capitalize font-bold hover:bg-violet-500 hover:text-violet-100 transition-all duration-500 hover:shadow-lg linear'>explore more</Link>
                    } */}

                </div>
            </div>

            {/* service card */}

            <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 my-5 gap-5 cursor-default	'>
                {services.map(item => (
                    <Slide bottom>
                        <div className={`p-8 border-violet-100 border-2 rounded-xl hover:bg-white hover:border-transparent transition-all duration-500 hover:shadow-xl linear`}>
                            <button className={`bg-${item.color}-200 text-${item.color}-700 w-8 h-8 rounded-md `}>
                                {item.icon}
                            </button>
                            <h3 className='font-bold text-xl my-4'>{item.title}</h3>
                            <p className='text-gray-500 text-sm font-semibold'>
                                {item.desc}
                            </p>
                        </div>
                    </Slide>
                ))}

            </div>
        </section>
    )
}

export default Services
export const services = [
    { title: 'Overseas Secondment', color: 'violet', icon: <i class="fas fa-user"></i>, desc: 'Reforming a single work universe! Relieve the constraints and enhance your productivity by recruiting an industry professional from anywhere across the globe. The overseas secondment services let your business maximize productivity by extending your network on a global scale.' },
    { title: 'Life Support service', color: 'green', icon: <i class="fas fa-notes-medical"></i>, desc: 'Not just the knowledge, but the know-how. JIA supports and relieves your professional concerns with the detailed assistance of business methodology and culture with proficient international life support services. ' },
    
    { title: 'Secondment', color: 'cyan', icon: <i class="fas fa-history"></i>, desc: 'A network for the future! secondment services generate exceptional networking opportunities. With the service, you can join hands with a reputed expert to fix the industrial requirements of your consortium. ' },
    { title: 'All Service Reliable', color: 'blue', icon: <i class="fas fa-recycle"></i>, desc: 'With pride in our discipline, we work consistently to grow the graph upward. We are always ready at your service and are all ears for your knock on our door. ' },
    { title: 'Trade Test Centre', color: 'red', icon: <i class="fas fa-address-card"></i>, desc: 'Restricting the best in demand! We certify trained candidates with proven skills and expertise for empowering projects and industries across the world. ' },
    { title: 'Background verification', color: 'orange', icon: <i class="fas fa-users"></i>, desc: 'Trust like a home! Background screening of employees is critical for companies and businesses, as they demand a clear authorization of the process to avoid the involvement of risk factors.' },
    { title: 'HR Management software', color: 'purple', icon: <i class="fas fa-project-diagram"></i>, desc: 'Everything under a single roof! The technology of HR Management software delivers an all-in-one solution for companies by managing the services involving people management, performance and talent management, managing employee benefits, finance and accounting, hiring and onboarding, time and attendance management, etc' },


    {title:'HR Diagnostic tool',color:'teal',icon:<i class="fas fa-user-tie"></i>,
    desc:`The stated tool has been designed to provide a full diagnostic 
    analysis pertaining to each area in the HR department in addition to the level of maturity of these activities from a design and implementation aspects. Our HR Diagnostic tool assist our clients to identify the services which matches their business needs. 
    `},
    {title:'Job Fit Tool',color:'emerald',icon:<i class="fas fa-heartbeat"></i>,
    desc:`The stated tool is designed to provide behavioral and technical assessment for each
     employee at your organization. The stated tool has the capability to provide the level of competency
      for each area required within the job description which can be linked to the training needs, career path and succession plan.`},
    
    {title:'Management Style Assessment Tool',color:'green',icon:<i class="fas fa-user-ninja"></i>,
    desc:`The stated tool has been designed to assist 
        in the identification of the managerial style adopted by each candidate. We use the ‘MSAT’ prior to the Leadership development programs in order to be customized 
        for the concerned candidates.`},

    {title:'Cultural Assessment Tool',color:'lime',icon:<i class="fas fa-glasses"></i>,
    desc:`The stated tool has been designed to identify the organizational and departmental climates that is created
     by the executives and respective managers within a particular organization. The stated tool assists us in understanding 
     the pain areas and the required solutions for each organization from a cultural aspect.`},
    
    {title:'Culture and Capability',color:'amber',icon:<i class="fab fa-canadian-maple-leaf"></i>,
    desc:`The passion for Business advisory in JIA can be seen and felt in all its members who share a common customer-centric approach tied through integrity at the core of service delivery. 
    Job in Arabia has the capability to address a multitude of client requirements ranging
     from the broad-based needs of multinational organizations to the specific service needs of small and medium enterprises.`},
    
    {title:'Service Line Summary',color:'yellow',icon:<i class="fas fa-concierge-bell"></i>,
    desc:`The servility of JIA consultants has earned the acknowledgement from Human Capital advisory, 
    Regulatory compliance advisory, Corporate governance, Risk Management consulting and Economic Consulting services. 
    Our ambitious solutions help businesses rule out their complexities and conjugates progress and productivity 
    thus gaining their desired industrial destiny.`
    },
    
]