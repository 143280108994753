import { ArrowBack } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const BackButton = () => {
    const navigate = useNavigate()
    
    return (
        <div className={`mr-5`}>
            <Button color='inherit' startIcon={<ArrowBack />} onClick={() => navigate('/jobs-in-kuwait/')}>
                Back
            </Button>
        </div>
    )
}

export default BackButton