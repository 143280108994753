import React from 'react'
import Slider from 'infinite-react-carousel';
import FadeInTextWithBar from '../../Components/TextAnimation/FadeInTextWithBar';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

import img1 from '../../Assets/projects/Kuwait Airport - Limak.png'
import img2 from '../../Assets/projects/South Al Mutlaa - Kolin.png'
import img3 from '../../Assets/projects/South Al Mutlaa - Polatyol.png'
import img4 from '../../Assets/projects/South Al Mutlaa - CGGC.png'
import img5 from '../../Assets/projects/Jamal Abdul Nasser - Rizzani De Eccher.png'
import img6 from '../../Assets/projects/Kuwait Airport - ACTS.png'

import img7 from '../../Assets/projects/South Al Jahra - Sinohydro.png'
import img8 from '../../Assets/projects/Sabah Al Salem - CSCEC.png'
import img9 from '../../Assets/projects/Kuwait Airport - ACTS.png'
import img10 from '../../Assets/projects/Kuwait Airport - Temsan.png'
import img11 from '../../Assets/projects/Kuwait Airport - Struts.png'
import img12 from '../../Assets/projects/Al Zour - Hyundai.png'
import img13 from '../../Assets/projects/Al- Mulla Group.png'
import img14 from '../../Assets/projects/Sabah Al Ahmed - Sinohydro.png'
import img15 from '../../Assets/projects/Sabah Al Ahmed - First Group of General Trading.png'
import img16 from '../../Assets/projects/Sabiya West - BilFinger.png'
import img17 from '../../Assets/projects/future/Kuwait National Rail Road.png'
import img18 from '../../Assets/projects/future/Madinat Al Hareer (Silk City Project).png'
import img19 from '../../Assets/projects/future/South Saad Al-Abdullah City (Smart City).png'
import img20 from '../../Assets/projects/future/KIPIC - Al Zour Petrochemical Complex.png'
import img21 from '../../Assets/projects/future/Kuwait Islands Development.png'
import { Helmet } from 'react-helmet';


const Project = () => {

    const OurProjects = [
        { projectName: 'KUWAIT INTERNATIONAL AIRPORT TERMINAL II',subHead:'' ,
        desc: <div><p>The new passenger terminal building footprint exceeding an area of
        150,000 will be able to handle 25 million passengers annually and will replace the existing
        terminal. The project aims to be the first passenger terminal in the world to attain this
        level of environmental accreditation.</p>
        <p className='mt-3'>JIC is associating with LIMAK, the key contractor right from the inception of this project and
        has earned a name as the leading manpower supplier for them. We provide the manpower
        solutions to LIMAK’s sub-contractors too.</p></div>, img: img1 , cValue:'1.3 Billion KWD'},

        
        { projectName: 'SOUTH AL-MUTLAA HOUSING PROJECT', 
        subHead:'',
        desc: <div><p>Construction work of the main roads and main infrastructure of the
        South Al Mutlaa City which is being newly developed on an area of approximately 100 Sq. Km
        located at the south of Kuwait City and ready for 350,000 people to live.</p>
        <p className='mt-3'>JIC’s capabilities and expertise as the exclusive manpower supplier for this project is well
        acknowledged by KOLIN and their sub-contractors.</p></div>, img: img2 ,cValue:'288 Million KWD'},
        
        { projectName: 'SOUTH AL-MUTLAA HOUSING PROJECT', subHead:'',
        desc:<div><p>Public Authority for Housing Welfare (PAHW) is undertaking the
        construction of South Al Mutlaa Major Infrastructure works for 4999 plots project areas N1
        and N4 in Al Mutlaa in Kuwait. It is located 40 km northwest of Al-Jahra City. The aim of
        the project is to provide better housing facility in the region. The current profile Includes the
        development of Al Mutlaa city comprising of 30,000 residential units.</p>
        <p className='mt-3'>JIC’sinvolvement asthe biggest manpowersupplier for this project has won accoladesfrom
        M/s. Polatyol.
        </p></div>,
         img: img3,cValue:'50 Million KWD' },
        
        { projectName: 'SOUTH AL-MUTLAA HOUSING PROJECT',subHead:'',
         desc: <div><p>Construction, Completion and maintenance of roads and infrastructure 
         networks at South Al-Mutlaa Residential Project.</p>
         <p className='mt-3'>
         JIC supplies quality human resource assets for CGGC ongoing projects in Kuwait.
         </p>
         </div>
         , img: img4 ,cValue:'216 Million KWD'},
        
        { projectName: 'JAMAL ABDUL NASSER STREET PROJECT', subHead:'',
        desc: <div><p>This is an Express Way construction including six cloverleaf intersections
        and other pertaining structures to enhance motorways and roads in the State of Kuwait. The
        project is located in the western region of Kuwait City (the Capital), extending from the Jahra
        Gate roundabout (aka Sheraton roundabout) to the east side of Grenada area – North of the
        capital city.</p>
        <p className='mt-3'>JIC has been one of the best active supporters of ROBT joint venture project by way of
manpower supply and vehicle rental since the project commencement
            </p></div>
       , img: img5,cValue:'214 Million KWD' },
        
        { projectName: 'SOUTH AL-JAHRA LOW-COST HOUSING CITY',subHead:'',
         desc: <div>
            <p>The construction, operation and transfer (BOT) of a 1,015,000 m² Labor
         City which to provide adequate and affordable housing for 20,000 male, bachelor, expatriate
         workers. Located in the north of Kuwait and lies: 4km south of Al-Salmi Road & 15.5km west
         of Al-Jahra City. The implementation includes all road works and infrastructure services, as
         well as public utilities building, power transformers sub-stations and wastewater facilities.
         Along with the commercial and public services. </p>
         <p className='mt-3'>JIC is the exclusive HR solution provider for this project.</p></div>, 
         img: img7,cValue:'68 Million KWD' },
        
        { projectName: 'SABAH AL-SALEM UNIVERSITY CITY',
        subHead:'',
        desc: <div>
            <p>
            One of the largest educational campuses in the world on a built-up
        area of 3.7 million sqm. The new university will be co-educational and accommodate up to
        30000 students. The project will comprise more than 25 faculty buildings and contain a 600
        bed hospital, car parks with space for more than 32000 vehicles, dormitories,sportsfacilities,
        auditoriums and accommodates 3000 students.
            </p>
            <p className='mt-3'>JIC provides technically qualified human resources to the Subcontractor – CSCEC</p></div>, img: img8,cValue:'174 Million KWD' },
        
        { projectName: 'KUWAIT INTERNATIONAL AIRPORT TERMINAL II',subHead:'Sub-Contractor of LIMAK',
         desc: <div><p>Advanced Construction Technology Services For Examining Soil
         & Engineering Materials Co. WLL (ACTS) is involved in the laboratory and material testing
         services connected with the Kuwait International Airport Terminal II project.</p>
         <p className='mt-3'>Right from the start, JIC has been providing the best human resources support to ACTS,
         enabling them to achieve their targets.</p></div>,
          img: img9,cValue:'' },
        
        { projectName: 'KUWAIT INTERNATIONAL AIRPORT TERMINAL II',
        subHead:'Sub-Contractor of LIMAK', desc:<div>
            <p>
            Temsan is into roof cassette shell system manufacturing jobs for the
        Kuwait International Airport T-II. Fabrication of side plates & cross-piece of the roof shell
        panels (50000 tons) connected with the Kuwait International Airport Terminal II project.
            </p>
        <p className='mt-3'>JIC’s capabilities asthe best manpowersupplier have been greatly acknowledged by Temsan.
            </p></div> , img: img10,cValue:'' },
        
        { projectName: 'KUWAIT INTERNATIONAL AIRPORT TERMINAL II',
        subHead:`Sub-Contractor of LIMAK`,
        desc: <div><p>
            Struts is into the complete roofing of the Kuwait International Airport
        Terminal-IIproject. Theuniquely slopingroofruns alongclean, crisplines, lendinga flawlessly
        aerodynamic form to the New Passenger Terminal – II of the Kuwait International Airport. 
            </p>
            <p className='mt-3'> Struts have joined hands with JIC accepting us as the sole HR solution provider for their
        prestigious project. </p></div>, img: img11,cValue:'' },
        
        { projectName: 'AL ZOUR REFINERY PROJECT',subHead:`Main Contractor A Joint Venture of Hyundai Heavy Industries
        &Daewoo E&C & Fluor Corporation`,
         desc: <div><p>The project involves the construction of an oil refinery
         with a production capacity of 700,000 – 800,000 barrels per day (BPD) and the
         largest Liquefied Natural Gas (LNG) import facility. </p><p className='mt-3'>JIC’s capabilities as the best manpower supplier have been greatly acknowledged by Hyundai LNG.</p></div>, 
         img: img12 ,cValue:''},
        
        { projectName: 'Mechanical, Electrical and Plumbing (MEP) Construction Services Al - Mulla Group', subHead:'',
         desc: <div><p>
            The MEP Construction division is also an approved contractor for high-value projects
         for KOC and KNPC. The division’s installed base for electrical infrastructure is up to 1 GW, with a refrigeration capacity
         of 500,000 TR. Three modern CNC-operated factories produce HVAC ducts, dampers, grills, and diffusers for the
         division in Kuwait.
            </p><p className='mt-3'>Al-Mulla
          has joined hands with JIC accepting us as the sole HR solution provider for their prestigious projects.</p></div>
            , img: img13, cValue:'' },
        
        { projectName: 'Sabah Al Ahmed Township: Residential Complex',subHead:'Sub-Contractor of Sinohydro',
         desc: <div><p>Construction of 597 houses, Construction of public buildings, Construction of roads and
         infrastructure works, Construction of parking facilities, Construction of associated facilities. </p>
         <p className='mt-3'>Sinohydro has joined hands with BlueRays for construction needs of the project.</p></div>, img: img14,
         cValue:'63.9 M KWD'
         },
        
        { projectName: 'Sabah Al Ahmed Township: Residential Complex',
        subHead:'Sub-Contractor of First Group for General Trading & Contracting',
         desc: <div><p>Construction of 587 houses, Construction of public buildings,Construction of roads
         and infrastructure works, Construction of parking facilities, Construction of associated facilities.</p>
        <p className='mt-3'> First Group has joined hands with JIC for construction needs of the project.</p></div>
         , img: img15,
         cValue:'68.3 M KWD' },

        { projectName: 'Sabiya West gas-fired combined-cycle power plant project Kuwait', subHead:'',
        desc: <div><p>The Sabiya West power station has been undergoing an upgrade to increase
        its total output by more than 7% as part of the New Kuwait Vision 2035.Total generating capacity of 2GW.</p>
        <p className='mt-3'>JIC’s capabilities as the best manpower supplier Have been greatly acknowledged by Bilfinger.</p></div>
        ,
         img: img16 ,cValue:''},


        { projectName: 'Kuwait National Rail Road (KNRR)', subHead:'',
        desc: <div><p>A future railway system, connecting Kuwait City to its Airport, Seaports and
        other GCC countries. This will boost Kuwait’s economic development and
        also serve freight and passenger movements over a rail network of over 500
        kilometers. The project will be implemented as a build, operate, and transfer
        (BOT) agreement.</p>
        <p className='mt-3'></p></div>
        ,
         img: img17 ,cValue:'Upcoming Projects'},

        { projectName: 'Madinat Al Hareer (Silk City Project)', subHead:'',
        desc: <div><p>
            Proposed 250 km2 (62,000-acre) planned urban area in Subiya, northern
Kuwait. This will contain numerous tourist attractions, hotels, spas, and
public gardens, a large business center, conference areas, environmental
areas, athletic areas, and areas that concentrate on media, health,
education, and industry. The development will cost an estimated <span className='font-black'>25 billion
Kuwaiti Dinars</span>.
        </p>
        <p className='mt-3'></p></div>
        ,
         img: img18 ,cValue:'Upcoming Projects'},

        { projectName: 'South Saad Al-Abdullah City (Smart City)', subHead:'',
        desc: <div><p>Under Public Authority for Housing Welfare (PAHW), the implementation
        of South Saad Al-Abdullah City project (worth a total of  <span className='font-black'> KD 1.2 billion</span>), is
        getting finalized. The city will be environmental-friendly and will rely on solar
        energy in the operation of traffic lights and majority of government
        buildings. It will contain about 25,000 to 40,000 plotssuch that each
        plot will be of an area of 400 square meters.</p>
        <p className='mt-3'></p></div>
        ,
         img: img19 ,cValue:'Upcoming Projects'},

        { projectName: 'KIPIC-Al Zour Petrochemical complex', subHead:'',
        desc: <div><p>KuwaitIntegrated Petrochemical Industries Company (KIPIC), is a subsidiary
        of Kuwait Petroleum Corporation (KPC). The forthcoming Olefins project,
        estimated at a cost of <span className='font-black	'>3,000 million Kuwaiti Dinar</span>, will convert butane to
        isobutane and producing clean-fuels blending components, including
        methyl tert-butyl ether (MTBE).</p>
        <p className='mt-3'>This petrochemical complex which will produce 940,000 tons of
polypropylene annually, 1.4 million tons of paraxylene annually, 420,000 tons
of gasoline annually and 208,900 tons of fuel annually using propane and
naphtha from Al-Zour Refinery
        </p></div>
        ,
         img: img20 ,cValue:'Upcoming Projects'},

        { projectName: 'Kuwait Island Development', subHead:'',
        desc: <div><p>Kuwait intends to invest 48 billion Kuwaiti Dinars for the development of five
        islands — Boubyan, Warba, Failaka, Maskan and Aouha. The Development
        of Islands Committee in the Council of Ministers estimates that the country
        will earn around <span className='font-black	'>12 billion KWD</span> annually once the mega projectis completed.</p>
        <p className='mt-3'></p></div>
        ,
         img: img21 ,cValue:'Upcoming Projects'},

        
    ]

    const settings = {
        arrows: false,
        centerPadding: 40,
        duration: 500,
        gutter: 100,
        shift: 50,
        virtualList: true,
        wheel: true,
        arrows: true
    };


    return (
        <main className=''>
            <Helmet>
                <title>Job In Arabia | Project</title>
                <link rel="canonical" href="https://job-in-arabia.com/projects" />
            </Helmet>
            {/* <section className=' py-20  container xl:px-20 px-5  relative m-auto'> */}
            <section className=' py-20  grid lg:grid-cols-7  container  px-5  relative m-auto'>
                {/* <div className='grid grid-cols-1 gap-5 md:flex flex-col md:flex-row  justify-between md:items-center border-b-2'>
                    <div>

                        <h2 className='font-black text-2xl my-4'>Our Projects</h2>
                        <p className='text-sm text-gray-500 font-semibold w-96 mb-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    </div>
                </div> */}
                <div className='lg:col-span-1 hidden lg:flex items-center'>
                    <h1 className='lg:absolute hidden lg:block lg:-rotate-90 md:text-3xl xl:text-6xl lg:text-4xl font-extrabold text-gray-400 border-gray-400 border-b-4 xl:-ml-20 lg:-ml-20 '>
                        <Fade  >
                            Our Projects
                        </Fade>
                    </h1>
                </div>

                <div className='lg:col-span-6 w-full hidden lg:block'>
                    <Slider {...settings}>
                        {OurProjects.map(item => (

                            <div className='grid grid-cols-1 md:py-14'>

                                <div className='flex justify-between h-96' >

                                    <div className='flex flex-col justify-end  '>

                                        <FadeInTextWithBar
                                            title={item.projectName}
                                            titleClass={'font-black text-xl'}
                                            titlebg={'bg-black'}
                                            description={item.desc}
                                            descClass={'text-sm text-gray-500 font-semibold w-96 mb-5 mix-blend-difference'}
                                            descbg={'bg-gray-200'}
                                        />


                                        {/* <div className="flex my-4 items-baseline">
                                            
                                            <h3 className='font-black text-xl '>{item.projectName}</h3>
                                        </div>
                                        <p className='text-sm text-gray-500 font-semibold w-96 mb-5 mix-blend-difference	'>
                                            {item.desc}
                                        </p> */}
                                    </div>
                                       
                                            
                                            <img className=' w-full ml-12 object-cover object-center project____img'
                                            src={item.img} alt="" />
                                             {item.cValue&&<div className='relative' style={{textAlign:'center'}}>
                                            <span className='absolute top-4 right-5 w-max rounded bg-white p-2'>{item.cValue}</span>
                                        </div>}
                                        
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                <div className='lg:col-span-6 w-full  lg:hidden block'>

                    {OurProjects.map((item, idx) => (

                        <div className='grid grid-cols-1 py-7 md:py-14'>

                            <div className={idx % 2 == 0 ? 'flex flex-col-reverse md:flex-row justify-between md:h-60' : 'flex  flex-col-reverse justify-between md:flex-row-reverse md:h-60'} >

                                <div className={idx % 2 === 0 ? 'flex flex-col w-full md:w-1/2 justify-end  ' : 'flex flex-col w-full md:w-1/2 justify-end pl-3 '}>

                                    <FadeInTextWithBar
                                        title={item.projectName}
                                        description={item.desc}
                                        titleClass={'font-black text-lg'}
                                        titlebg={'bg-black'}
                                        descClass={'text-sm text-gray-500 font-semibold w-96 mb-5 mix-blend-difference'}
                                        descbg={'bg-gray-200'}
                                    />

                                </div>

                                <img
                                    className='w-full md:w-1/2 h-60 object-cover object-center	rounded'
                                    src={item.img} alt="" />
                                    {item.cValue&&<div className='relative' style={{textAlign:'center'}}>
                                            <span className='absolute top-4 right-5 text-xs rounded w-max bg-white p-2'>{item.cValue}</span>
                                        </div>}
                            </div>
                        </div>
                    ))}

                </div>

                <div className='w-full absolute bottom-0 lg:block hidden'>
                    <p className='text-gray-500 text-center  animate-pulse text-xs'>scroll to view next project</p>
                </div>
            </section>
        </main >
    )
}

export default Project