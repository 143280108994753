import React from 'react'
import { Link } from 'react-router-dom'
import AboutImg from '../../Assets/Vectors/Team-cuate.svg'
import SectionButton from '../../Components/SectionButton/SectionButton'
import TextReveal from '../../Components/TextAnimation/TextReveal'
import Slide from 'react-reveal/Slide';


const AboutUs = () => {
    return (
        <section className='py-20 md:pb-24 lg:py-28'>
            <div className='grid lg:grid-cols-2 gap-5 items-center'>
                <div className='flex justify-center'>
                    <img src={AboutImg} className={'lg:w-full md:w-3/4'} alt="" />
                </div>
                <div>
                    <SectionButton ButtonTitle={'About us'} />
                    <TextReveal tag={'h2'} tagClassName={'font-black text-2xl my-4'} content={'About Job In Arabia'} />
                    {/* <h2 className='font-black text-2xl my-4'>About Job In Arabia</h2> */}
                    <Slide bottom>

                        <p className='text-sm text-gray-500 font-semibold  mt-5 '>
                            Job In Arabia took its birth to progress the prospects of the human resource sector. The company is the milestone of our consortium Jobin International Company which is acknowledged for its services and profound vision to achieve developmental prospects. The precise investment of human capital can return futuristic fortunes by enhancing the progress and productivity of the sector.
                        </p>
                        <p className='text-sm text-gray-500 font-semibold  my-5 '>
                            Companies and Businesses always demand a quality workforce that has the efficacy to value their investment. Organizations get involved in hard assessments in finding quality candidates. The industry responsibility of Job In Arabia is to train, educate and provide healthcare to candidates by moulding them fit for workplaces across the globe. With the right candidate, the companies can return improved revenue and enhanced territory, and it can make other employees inspire and work with discipline. We source International and Local Human resources, that have the prowess and potential to make things that value.
                        </p>
                    </Slide>
                    <Link to={'/about'} className=' border-violet-500 border-2 px-4 py-1 rounded text-violet-500 capitalize font-bold hover:bg-violet-500 hover:text-violet-100 transition-all duration-500 hover:shadow-lg linear'>Explore more</Link>
                </div>
            </div>
        </section>
    )
}

export default AboutUs