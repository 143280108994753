import styled from '@emotion/styled'
import { ArrowBack } from '@mui/icons-material'
import { Breadcrumbs, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Context } from '../../Common/Context/Context'
import BackButton from '../../Components/BackButton/BackButton'
import Card from '../../Components/Cards/Card'
import MyJobsDropdown from '../../Components/Dropdown/MyJobsDropdown'
import Overlay from '../../Components/Overlay/Overlay'
import JobCardLoader from '../../Components/SkeltonLoader/JobCardLoader'
import { ManageAPIs } from '../../Functionalities/Logic'
import JobCards from '../JobsSection/JobCards'
import NoDataGif from '../../Assets/No data.gif'
import TextField from '../../Components/InputField/TextField'

const AppliedJobs = () => {
  const navigate = useNavigate()

  const { IsSidemenuOpen, Token } = useContext(Context)
  const [data, setdata] = useState([])
  const [statusId, setstatusId] = useState(0)
  const [isModalOpen, setisModalOpen] = useState(false)
  const [Loader, setLoader] = useState(true)


  useEffect(() => {
    getdata()
    setisModalOpen(false)
  }, [statusId])


  const getdata = () => {
    let path = '/jobpost/'
    let param = `applied_jobs=${statusId == 0 && 'true'}&selected_jobs=${statusId == 2 && 'true'}&rejected_jobs=${statusId == 3 && 'true'}&shortlisted_jobs=${statusId == 1 && 'true'}`

    let result = ManageAPIs('get', path, '', param, Token)
    result.then((res) => {
      console.log(res)
      setLoader(false)
      if (res.data) {
        // setpostCount(res.data.count)
        setdata(res.data.results)
        // setNext(res.data.next)
        // setPrev(res.data.previous)
      } else {
        setdata([])
      }
    })

      .catch((err) => {
        console.log(err)
        setLoader(false)
      })
  }

  const Status = [
    { id: 0, value: 'Applied' },
    { id: 1, value: 'Shortlisted' },
    { id: 2, value: 'Selected' },
    { id: 3, value: 'Rejected' },
  ]

  const handleChangeStatus = (id) => {
    setstatusId(id)
  }

  return (
    <main className=' bg-slate-50 min-h-screen md:py-10 px-3 sm:px-5 relative'>

      <div className=' m-auto grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 md:gap-7 xl:px-16 py-0 container '>
        <div className='grid col-span-3 grid-cols-2 mt-4'>
          <BackButton />

          <div className="flex justify-end">
            <MyJobsDropdown handleClick={handleChangeStatus} value={statusId} datas={Status} />
          </div>
        </div>
        <div></div>

      </div>

      <div className={` m-auto grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 md:gap-7 xl:px-16 py-0 container `} >

        <div className='grid col-span-3 mt-4'>
          <div>


            {/* <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Typography color="text.primary">{statusId == '0' ? 'Applied' : statusId == '1' ? 'Shortlisted' : statusId == '2' ? 'Selected' : statusId == '3' && 'Rejected'} Jobs</Typography>
            </Breadcrumbs> */}


            {/* <h1 className='text-lg lg:text-xl font-bold'> Jobs</h1> */}
            {data.length ? data.map(item => (
              <JobCards Position={item.job_name} Location={item.location} Id={item.id} Data={item} />

            ))
              :



              Loader ?
                <JobCardLoader />
                :
                <Card>
                  <div className='text-center flex flex-col items-center justify-center h-full text-sm font-medium'>
                    <img src={NoDataGif} className={'w-72'} alt="no data found gif" />
                    <p className={'font-semibold'}>No data found</p>
                  </div>
                </Card>



            }
          </div>
        </div>

        <div className='sticky top-20 h-fit hidden lg:block mt-4'>
          <Card>
            <div className="flex justify-between item-center">
              <h1 className='font-semibold text-base text-blue-500'>Write a review</h1>
              {/* <button className='text-blue-400 text-sm' >clear</button> */}
              {/* <CustomButton size='small' onClick={() => setstatusId(null)}>clear</CustomButton> */}
            </div>

            <div className='mt-4' />
            <TextField placeholder={'Type message.....'} type={'textarea'} // label={'give feedback'} value={} onchange={} name={} 
              rows={9} cols={1}
            />
            <div className='flex justify-end'>
              <Button variant="contained" size='small' >Send</Button>
            </div>


          </Card>
        </div>
      </div>

      <div className={`flex lg:hidden flex-col items-center py-2  shadow-lg fixed w-full bg-white inset-x-0 bottom-0 transition-all duration-500 ease-in-out h-full items-start ${IsSidemenuOpen ? 'hidden' : 'z-40'}  ${isModalOpen ? 'translate-y-1/2' : ' filter__modal'}`}>
        <button className={`absolute -mt-10 ${!isModalOpen && 'hidden'}`} onClick={() => setisModalOpen(false)}>
          <i class="fas fa-times-circle text-gray-100"></i>
        </button>

        <button className=' px-3 text-sm  py-1 font-semibold mr-2 my-1 ' onClick={() => setisModalOpen(true)}>
          <i class="fas fa-list mr-2"></i>
          Filter
        </button>

        <div>
          <div className='w-screen p-5 h-auto'>
            <div className="flex justify-between">
              <h1 className='font-semibold text-base text-blue-500'>Category</h1>
              {/* <button className='text-blue-400 text-sm' >clear</button> */}
              <CustomButton size='small' onClick={() => setstatusId('0')}>clear</CustomButton>
            </div>

            <div className='mt-4' />
            <CustomFormControl>
              <CustomRadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => setstatusId(e.target.value)}
                value={statusId}

              >
                <CustomFormControlLabel value={0} control={<Radio size='small' />} label="Applied" labelPlacement="start" />
                <CustomFormControlLabel value={1} control={<Radio size='small' />} label="Shortlisted" labelPlacement="start" />
                <CustomFormControlLabel value={2} control={<Radio size='small' />} label="Selected" labelPlacement="start" />
                <CustomFormControlLabel value={3} control={<Radio size='small' />} label="Rejected" labelPlacement="start" />

              </CustomRadioGroup>
            </CustomFormControl>

          </div>



          {/* <button>Apply Filter</button> */}
        </div>
      </div>

      {isModalOpen &&
        <Overlay />
      }

    </main>
  )
}

export default AppliedJobs
const CustomRadioGroup = styled(RadioGroup)(({ }) => ({
  width: '100%',
  justifyContent: 'space-between',
  margin: '0'
}));
const CustomFormControlLabel = styled(FormControlLabel)(({ }) => ({
  width: '100%',
  justifyContent: 'space-between',
  marginLeft: '0',
}))
const CustomFormControl = styled(FormControl)(({ }) => ({
  width: '100%',
}))

const CustomButton = styled(Button)(({ }) => ({
  textTransform: 'capitalize',
  fontWeight: 500,
  fontSize: 'smaller',
  fontFamily: `'Raleway', 'sans-serif'`,
  padding: '0'
}));