import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Logo from '../../Assets/jobin-international-logo.png'
import { ShowFailedMessage, ShowSuccessMessage } from '../../Functionalities/Functions'
import { ManageAPIs } from '../../Functionalities/Logic'
import { Helmet } from "react-helmet";
import JobCards from './JobCards'
import { useContext } from 'react'
import { Context } from '../../Common/Context/Context'

const JobDetails = () => {
    const { state } = useLocation()
    const navigate = useNavigate()
    const { id } = useParams()
    const [data, setdata] = useState({})
    const {Token} = useContext(Context)


    useEffect(() => {
        if (state) {
            setdata(state)
        } else {
            getdata()
        }

        return () => {
            setdata({})
        }
    }, [state])

    const getdata = () => {
        let path = '/jobpost/'
        let param = `id=${id}`
        let token = Token ? Token : ''
        let result = ManageAPIs('get', path, '', param, token)

        result.then((res) => {
            console.log({ res })
            if (res.data.results) {
                setdata(res.data.results[0])
            }
        })

            .catch((err) => {
                console.log(err)
            })
    }

    console.log(data)

    const ApplyJob = (id) => {
        let path = "/jobpost/jobrequest/";
        let token = Token ? Token : ''

        var formdata = new FormData();
        formdata.append("job", id);
        formdata.append("status", "0");

        let result = ManageAPIs("add", path, formdata, "", token);
        result
            .then((res) => {
                console.log(res);
                if (res.data.Status) {
                    ShowSuccessMessage(res.data.Message);
                    navigate('/jobs-in-kuwait')
                } else {
                    ShowFailedMessage(res.data.Message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${data.job_name} | Jobs In Kuwait`}</title>
                <meta name="title" content={`${data.job_name} | Jobs In Kuwait`} />
                <meta name="description" content={data.description?.slice(0, 120)} />
            </Helmet>
            <JobCards
                Position={data.job_name}
                Location={data.location}
                Id={data.id}
                Data={data}
                handleClick={ApplyJob}
            />
        </div>

    )
}

export default JobDetails