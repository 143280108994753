import { Button } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react'

const MultiSelect = ({ label, arrayList, onSelect, SkillSelected, Search, SearchValue, removeSkill, handleAddSkill, allList, userSkill }) => {
    const ref = useRef()
    const [isHidden, setisHidden] = useState(true)

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setisHidden(true)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    // console.log(arrayList.find(itm => itm).id)
    console.log({ allList })
    console.log({ arrayList })
    console.log({ SkillSelected })

    const filteredArray = arrayList.filter(itm => itm.name.toLowerCase().includes(SearchValue.toLowerCase()))
    return (
        <div className='relative'>
            {label &&
                <label htmlFor={label} className='capitalize font-medium text-sm ml-1'>{label}</label>
            }


            <div ref={ref}>


                <div className={`w-full bg-slate-100 p-1 rounded-md ${!isHidden && 'outline outline-2 outline-blue-500'}`}>
                    <div className='flex-wrap flex' >
                        {SkillSelected.map(item => (
                            <>
                                <button type='button' className='bg-blue-200 sm:px-3 px-1 text-xs  rounded py-1 font-semibold mr-2 my-1 cursor-default'>{item.name}
                                    <i class="fas fa-times ml-1 cursor-pointer" onClick={() => removeSkill(item.id)}></i></button>
                            </>
                        ))}
                        <input type="text" className='outline-none bg-slate-100 grow' style={{ border: 'none', width: 'max-content' }} onChange={Search} value={SearchValue} onClick={() => setisHidden(false)} />
                    </div>


                </div>
                <div className={`multi-select-dropdown w-full mt-2 ${isHidden ? 'hidden' : 'block'}`} >
                    <ul>
                        {filteredArray.map(item => (
                            <>
                                <li style={{ borderBottom: '1px solid #8f8f8f', cursor: 'pointer' }} onClick={() => onSelect(item.id)} className={SkillSelected.includes(item.id) ? 'bg-slate-100 p-1 cursor-pointer text-sm font-medium' : 'cursor-pointer p-1 text-sm font-medium '}>{item.name}</li>
                            </>
                        ))}
                        {/* {SearchValue.length >= 1 &&
                            <li>
                                <Button variant='contained' color='primary' onClick={handleAddSkill}>
                                    Add New Skill
                                </Button>
                                
                            </li>
                        } */}
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default memo(MultiSelect)