import React, { useEffect } from 'react'
import Slider from 'react-slick/lib/slider';
import SectionButton from '../../Components/SectionButton/SectionButton';

const Alliances = () => {

    const images = importAll(require.context('../../Assets/Client Logos', false, /\.(png|jpe?g|svg)$/));

    function importAll(r) {
        let a;
        // console.log(r.keys())
        // console.log(r.keys().filter(itm => a = itm.replaceAll(/\D/g, ''),))
        let arr = []
        // r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        r.keys().forEach((item, index) => {
            arr.push(r(item))
        });
        return arr
    }

    // console.log(images.sort((a, b) => parseFloat(a) > parseFloat(b)))


    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        initialSlide: 0,
        autoplaySpeed: 2000,
        //   cssEase: "linear",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    // initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    // initialSlide: 2
                }
            }
        ]
    };

    return (
        <section className='flex flex-col py-10 md:py-20 overflow-x-hidden'>
            {/* <h1 className='text-center text-xl font-bold text-gray-500'>Alliances</h1> */}

            <div>
                <SectionButton ButtonTitle={'Alliances'} />
            </div>
            {/* logos */}
            {/* <div className='alliences___'>
                {images.map(item => (
                    <img src={item}  alt="" />
                ))}
            </div> */}

            <div className='  py-5'>
                <Slider {...settings}>

                    {images.map(item => (
                        <div className='flex justify-center'>
                            <img src={item} className={'p-5 duration-500 ease-linear transition-all w-40 grayscale  hover:grayscale-0'} alt="" />
                        </div>
                    ))}

                </Slider>

            </div>
            {/* logos */}

        </section>
    )
}

export default Alliances