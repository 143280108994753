import React from 'react'
import CollabVector from '../../Assets/Vectors/vector.png'
import FadeInTextWithBar from '../../Components/TextAnimation/FadeInTextWithBar'
import Fade from 'react-reveal/Fade';


const HeroSection = () => {
    return (
        <section className=' m-auto xl:mb-32   flex flex-col-reverse lg:grid lg:grid-cols-2 lg:grid-cols-2 grid-cols-1 lg:gap-7 items-center'>
            <div className='w-full '>
                <FadeInTextWithBar
                    titleWidth={'md:w-full'}
                    title={'Win the Workplace with the Winning Workforce'}
                    titleClass={'lg:mt-16 font-bold xl:text-4xl sm:text-3xl text-2xl md:w-4/5 text-primary'}
                    titlebg={'bg-gray-300'}
                    description={<p>Ever seen the metamorphosis of Coal into a Diamond? We make you witness it! Every business has its winning formula invested in its workforce. Courage, hard work and discipline accomplish the fortunes of every business empire. No business can win the marketplace without proper human resources. But the right one, the torchbearer carries the vision of the organization that inspires your team to attain your feat. Workhorses find their nest in the best Human resource solutions, and Job In Arabia makes up the work, productivity, consistency, and development right for your business</p>}
                    descClass={'text-sm text-gray-500 md:w-full mt-5 text-sm text-gray-500 font-semibold'}
                    descbg={'bg-gray-300'}
                />
                {/* <h1 className='font-bold lg:text-4xl sm:text-3xl text-2xl md:w-3/4 text-primary'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                </h1> */}
                {/* <TextReveal content={'Lorem ipsum dolor sit amet, consectetur adipiscing elit,'} tag={'h2'} tagClassName={'font-bold lg:text-4xl sm:text-3xl text-2xl md:w-3/5 text-primary'} /> */}
                {/* <p className='text-sm text-gray-500 md:w-3/4 mt-5 text-sm text-gray-500 font-semibold'>
                    <Fade >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Fade>

                </p> */}
            </div>
            <div className='w-full mb-10 md:mb-0 lg:h-96' >
                <img onBlurCapture loading='lazy' src={CollabVector} alt="" className='img___entry___animation' />
            </div>
        </section>
    )
}

export default HeroSection