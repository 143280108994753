
import { Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../Common/Context/Context'
import Card from '../../Components/Cards/Card'
import ReactHtmlParser from 'react-html-parser';
import { useLocation } from 'react-router-dom'


const JobCards = ({ Data, handleClick }) => {

    const { pathname } = useLocation()
    const [trimContents, setTrimContents] = useState(true)

    const { UserID, Token, UserData } = useContext(Context)
    const navigate = useNavigate()
    // console.log(UserID)
    // console.log(Data.job_requests.some(itm => itm.user == UserID))
    useEffect(() => {
        pathname.includes('/job-details') && setTrimContents(false)
    }, [pathname])


    return (

        <Card>
            <div className="grid gap-2">
                <div className='flex items-center'>
                    <div>
                        <img
                            className='rounded-full h-10 w-10  object-cover'
                            src={Data.avatar}
                            alt="" />
                    </div>
                    <div className='ml-2'>
                        <h3 className='font-bold text-base'>{Data.job_name} </h3>
                        <h6 className='text-xs '>Full Time | {Data.location}</h6>
                    </div>
                </div>

                <div>
                    <div className='text-gray-500 text-sm mt-2'>

                        {trimContents && (Data.description?.length + Data.requirements?.length > 149) ?

                            <div className=' transition-all  duration-500 ease-in-out'>
                                <h5 className='font-semibold mb-1 mt-5 italic  text-black'>Job Description:</h5>
                                <p className='indent-5 mb-3'>
                                    {ReactHtmlParser(Data.description.slice(0, 149) + '...')}
                                    {/* <span className='underline text-blue-600 font-medium cursor-pointer ' onClick={() => setTrimContents(false)}>read more</span> */}
                                </p>

                            </div>

                            :
                            <div className=' transition-all   duration-500 ease-in-out'>
                                <h5 className='font-semibold mb-1 mt-5 italic  text-black'>Job Description:</h5>
                                <p className='pl-0 '>
                                    {ReactHtmlParser(Data.description)}
                                </p>
                                <h5 className='font-semibold mb-1 mt-5 italic  text-black'>Requirements:</h5>
                                <div className=' pl-5 '>
                                    {ReactHtmlParser(Data.requirements)}

                                </div>
                                <h5 className='font-semibold mb-1 mt-5 italic  text-black'>Responsibility:</h5>
                                <div className='mb-5 pl-4'>
                                    {ReactHtmlParser(Data.responsibility)}

                                </div>
                                {/* {Data.description?.length + Data.requirements?.length + Data.responsibility?.length > 149 &&
                                    <span className='underline text-blue-600 font-medium cursor-pointer ' onClick={() => setTrimContents(true)}>read less</span>
                                } */}
                            </div>



                        }
                    </div>
                </div>





                <div className='flex items-center py-1 flex-wrap	'>
                    <p className='text-gray-500 text-xs font-bold mb-0 mr-2'>Experience:</p>
                    <p className='text-gray-400 text-xs font-medium mb-0 mr-2'>{Data.min_experience} - {Data.max_experience} year</p>

                </div>
                <div className='flex items-center py-1 flex-wrap	'>
                    <p className='text-gray-500 text-xs font-bold mb-0 mr-2'>Education:</p>
                    <p className='text-gray-400 text-xs font-medium mb-0 mr-2'>{!Data.education_type?.includes('null') ? Data.education_type : ''} - ({Data.education_requirements})</p>
                </div>

                <div className='flex items-center py-1 flex-wrap	'>
                    <p className='text-gray-500 text-xs font-bold mb-0 mr-2'>Skills required:</p>

                    {Data.skills?.map(item => (
                        <p className='bg-slate-100 sm:px-3 px-1 text-xs rounded py-1 font-semibold mr-2 my-1 cursor-default'>{item.name}</p>
                    ))}

                </div>

                <div className='border border-gray-300 border-b-0' />

                <div className='flex justify-between items-center flex-wrap'>
                    <h4 className='font-semibold text-sm'>{(Data.pay_min_amount)} - {(Data.pay_max_amount)} {Data.currency}<span className='font-medium'>/month</span></h4>
                    {(handleClick && !pathname.includes('job-details')) &&
                        <Button variant='contained' size='small' onClick={() => navigate(`/jobs-in-kuwait/${Data.job_name.replaceAll(' ', '-').toLowerCase()}/job-details/${Data.id}`)}>{'View Details'}</Button>
                    }
                    {pathname.includes('job-details') &&
                        <Button variant='contained' size='small' onClick={() => Token ? handleClick(Data.id) : navigate('/signin')}>{'Apply'}</Button>
                    }

                    {/* <button className='py-2 px-4 bg-blue-500 text-black text-sm font-semibold text-white rounded'>Apply Now</button> */}
                </div>
            </div>
        </Card>

    )
}

export default JobCards

