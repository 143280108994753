import './App.css';
import Router from './Common/Router/Router';
import Toast from './Components/Toast/Toast';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "react-phone-input-2/lib/bootstrap.css";

function App() {
  return (
    <>
      <Toast />
      <Router />
    </>
  );
}

export default App;
