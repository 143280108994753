import { OptionUnstyled } from '@mui/base'
import React from 'react'

const SelectField = ({ label, placeholder, value, onchange, name, options,isRequired }) => {
    return (
        <div>
            <label htmlFor={label} className='capitalize font-medium text-sm ml-1'>{label}
                {isRequired &&
                    <span className='text-red-500 font-medium text-2xl leading-3'>*</span>
                }

            </label>
            <select className='w-full bg-slate-100 p-2 rounded-md focus:outline-blue-500' value={value} name={name} id="" onChange={onchange}>
                <option value="" selected disabled>{placeholder}</option>
                {options.map(item => (
                    <option value={item.id}>{item.value.replaceAll('_', ' ')}</option>
                ))}

            </select>
        </div>
    )
}

export default SelectField