import React from 'react'

const TestimonialCard = ({ message, author }) => {


    return (

        <div className='shadow-lg rounded-xl p-7 flex flex-col justify-between bg-white w-full w-11/12	 mb-5 m-auto ' >

            <div >
                <p className='font-semibold text-gray-400'>{message}</p>
            </div>

            <div>
                <div className='h-px w-full bg-gray-400 my-3' />
                <div className='flex items-center'>
                    {/* user icon */}
                    {/* <div>
                        <img src="https://img.freepik.com/free-photo/young-handsome-man-with-beard-isolated-keeping-arms-crossed-frontal-position_1368-132662.jpg?w=2000"
                            className='w-16 h-16 rounded-full object-cover	object-center	' alt="" />
                    </div> */}
                    {/* user icon */}

                    {/* user name & designation */}
                    <div className='ml-4'>
                        <h5 className='font-bold text-lg text-gray-800'>{author}</h5>
                        <p className='font-medium text-xs text-gray-400'>{''}</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TestimonialCard