import React from 'react'
import AboutUs from '../Home/AboutUs'
import OurMission from './OurMission'
import MissionIcon from '../../Assets/Icons/mission.png'
import VisionIcon from '../../Assets/Icons/visionary.png'
import GoalIcon from '../../Assets/Icons/target.png'
import FadeInTextWithBar from '../../Components/TextAnimation/FadeInTextWithBar'
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import TextReveal from '../../Components/TextAnimation/TextReveal'
import { Helmet } from 'react-helmet'

const About = () => {
    const missions = [
        { icon: MissionIcon, title: 'Mission', desc: 'To modernize the prospects of the human resource sector by delivering skilled experts to businesses and organizations to enhance productivity and development in general.' },
        { icon: VisionIcon, title: 'Vision', desc: 'To become the torchbearer of industrial skill and knowledge, for the commendation of economic, developmental, and innovative outputs to the organization and the industry itself. ' },
        { icon: GoalIcon, title: 'Goal', desc: 'To assemble a quality workforce for international and local clients to perform their industrial responsibilities in Kuwait, assuring the consortium the opportunity to lead the market.' },
    ]
    return (
        <main className=' bg-slate-50  grid grid-cols-7 h-full'>
            <Helmet>
                <title>Job In Arabia | About</title>
                <link rel="canonical" href="https://job-in-arabia.com/about" />
            </Helmet>
            <div className='lg:col-span-1 hidden lg:flex items-center '>

                <h1 className='lg:absolute hidden lg:block lg:-rotate-90 md:text-3xl xl:text-6xl lg:text-4xl font-extrabold text-gray-400 border-gray-400 border-b-4  xl:-ml-10'>
                    <Fade  >
                        Our Mission
                    </Fade>
                </h1>


            </div>


            <div className="md:col-span-3 col-span-7 grid grid-cols-1 gap-7 lg:gap-16 px-4 md:px-0 md:ml-7 xl:py-28 lg:py-24 md:py-20 py-16">
                <TextReveal tag={'h1'} tagClassName={'lg:hidden block  text-3xl font-extrabold  border-b-4 w-max'} content={'Our Mission'} />

                {/* <h1 className='lg:hidden block  text-3xl font-extrabold  border-b-4 w-max'>Our Mission</h1> */}

                {missions.map(item => (
                    <div className='flex'>
                        <Fade>
                            <div className='flex items-center justify-center w-14  md:w-16 h-12  md:h-16 shadow-md  rounded-full '>
                                <img src={item.icon} className={'w-6 md:w-8 object-cover '} alt="" />
                            </div>

                        </Fade>

                        <div className='lg:w-96 w-full ml-4'>
                            {/* <FadeInTextWithBar
                                title={`Our ${item.title}`}
                                titleWidth={'w-32'}
                                titleClass={'sm:text-base lg:text-lg font-bold'}
                                titlebg={'bg-gray-300'}
                                
                            /> */}
                            {/* <h2 className=''> */}
                            <TextReveal content={`Our ${item.title}`} tag={'h2'} tagClassName={'sm:text-base lg:text-lg font-bold'} />


                            {/* </h2> */}
                            <p className='text-xs md:text-sm font-medium transition-opacity duration-500 delay-500 ease-in'>
                                <Slide bottom>
                                    {item.desc.slice(0, 200)}
                                </Slide>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="lg:col-span-3 md:col-span-4 hidden md:block">
                <div className='aboute___img h-full w-full'></div>

            </div>
        </main>
    )
}

export default About