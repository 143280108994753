import React from 'react'
import { Helmet } from 'react-helmet'
import AboutUs from './AboutUs'
import Alliances from './Alliances'
import HeroSection from './HeroSection'
import Services from './Services'
import Testimonial from './Testimonial'

const Home = () => {
    return (
        <main className=' bg-slate-50 min-h-screen   justify-center flex'>
            <Helmet>
                <title>Job In Arabia | Job portal Kuwait</title>
                <link rel="canonical" href="https://job-in-arabia.com/" />
            </Helmet>
            <section className=' container xl:px-20 px-5 py-0 relative'>
                <HeroSection />
                <AboutUs />
                <Services />
                <Testimonial />
                <Alliances />
            </section>
        </main>
    )
}

export default Home