import { Notifications, Person } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../Assets/jobin-international-logo.png";
import { Context } from "../../Common/Context/Context";
import Overlay from "../Overlay/Overlay";
import SideMenu from "./SideMenu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomModal from "../Modal/Modal";

const Header = () => {
  const {
    IsSidemenuOpen,
    setIsSidemenuOpen,
    UserData,
    setUserData,
    Token,
    setToken,
  } = useContext(Context);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [LogoutModal, setLogoutModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);




  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log({ UserData })

  const CloseModal = () => {
    setLogoutModal(false);
  };

  const handleLogout = () => {
    window.location.replace("/signin");
    localStorage.removeItem("job-in-arabia-userdetails");
    localStorage.removeItem("job-in-arabia-token");
    localStorage.removeItem("job-in-arabia-userdetails");
    setUserData({});
    setToken("");

    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function () {
    //   window.history.go(1);
    // };
    // window.location.reload();
    setLogoutModal(false);
  };

  if (!pathname.includes("/sign") && !pathname.includes("reset"))  {
    return (
      <header className="h-20 shadow-md bg-white w-full flex sticky top-0 z-50">
        <div className="container xl:px-16 flex justify-between items-center  m-auto lg:px-3 px-3">
          <div className="lg:w-1/5 w-1/2">
            <img
              src={Logo}
              alt=""
              className="xl:h-14 md:h-12 h-10"
              onClick={() => navigate("/")}
            />
          </div>
          {/* desktop navbar*/}

          <div className="w-3/5 lg:block hidden">
            <ul className="flex justify-evenly	">
              {navItems
                .filter((itm) => (!Token ? itm.title !== "My Profile" : itm))
                .map((item) => (
                  <Link
                    to={item.url}
                    className={`flex text-black hover:bg-slate-100  px-4 py-2 transition-all duration-500 ease-in rounded-full ${pathname === item.url && "bg-slate-100"
                      }`}
                  >
                    {item.icon}
                    <li className="ml-2 font-semibold text-sm ">
                      {item.title}
                    </li>
                  </Link>
                ))}
            </ul>
          </div>
          <div className="w-1/5  justify-end lg:flex hidden">
            {/* {Token &&
                            <button className='w-10 h-10 flex justify-center items-center  bg-slate-100 rounded-full xl:mr-5 lg:mr-3' >

                                <IconButton >
                                    <Notifications />
                                </IconButton>
                            </button>
                        } */}

            <div>
              <div className="flex ">
                {pathname.includes("/career") && (Token) ? (
                  <>
                    <button className="w-10 h-10 flex justify-center items-center  bg-slate-100 rounded-full mr-2">
                      <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <Person />
                      </IconButton>

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            navigate("/career/profile");
                            handleClose();
                          }}
                        >
                          Profile
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            navigate("/career/jobs");
                            handleClose();
                          }}
                        >
                          My Jobs
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setLogoutModal(true);
                            handleClose();
                          }}
                        >
                          Logout
                        </MenuItem>
                      </Menu>
                      {/* <i class="fas fa-user"></i> */}
                    </button>

                    <div className="flex items-start flex-col justify-center">
                      
                      <h1 className="text-sm font-semibold">{`${UserData.first_name ? UserData.first_name : ''
                        } ${UserData.middle_name ? UserData.middle_name : ""} ${UserData.last_name ? UserData.last_name : ''
                        }`}</h1>
                      <h3 className="text-xs font-medium text-gray-500">
                        {UserData.email == UserData.facebook_id
                          ? ""
                          : UserData.email}
                      </h3>
                    </div>
                  </>
                ) : !pathname.includes("/jobs-in-kuwait") ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/jobs-in-kuwait")}
                  >
                    Find A Job
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
          {/* desktop navbar*/}

          {LogoutModal && (
            <CustomModal open={LogoutModal} onClose={CloseModal}>
              <div>
                <p className=" font-medium">Are you sure want to logout ?</p>
              </div>
              <div className="flex justify-end mt-5">
                <div className="flex">
                  <Button color="inherit" onClick={() => setLogoutModal(false)}>
                    Cancel
                  </Button>
                  <div className="px-1" />
                  <Button variant="contained" onClick={handleLogout}>
                    Yes
                  </Button>
                </div>
              </div>
            </CustomModal>
          )}

          {/* mobile navbar*/}
          <div className={`flex justify-end lg:hidden w-1/2`}>
            {!pathname.includes("/jobs-in-kuwait") && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/jobs-in-kuwait")}
              >
                Find A Job
              </Button>
            )}

            <button
              className="ml-5"
              type="button"
              onClick={() => setIsSidemenuOpen(true)}
            >
              <i class="fas fa-bars"></i>
            </button>
          </div>
          {/* mobile navbar*/}

          <SideMenu />

          {IsSidemenuOpen && <Overlay />}
        </div>
      </header>
    );
  } else {
    return null;
  }
};

export default Header;
export const navItems = [
  { title: "Home", icon: <i class="fas fa-home  text-sm  "></i>, url: "/" },
  {
    title: "About",
    icon: <i class="fas fa-landmark text-sm  "></i>,
    url: "/about",
  },
  {
    title: "Projects",
    icon: <i class="fas fa-drafting-compass"></i>,
    url: "/projects",
  },
  // { title: 'Career', icon: <i class="fas fa-briefcase  text-sm  "></i>, url: '/career' },
  {
    title: "Contact",
    icon: <i class="fas fa-paper-plane  text-sm  "></i>,
    url: "/contact",
  },
  // { title: 'My Profile', icon: <i class="fas fa-address-card text-sm  "></i>, url: '/profile' },
];
