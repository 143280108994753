

export const protocol = 'https://'
export const host = "wwwjicit.com"; //live

// export const protocol = 'http://'
// export const host = "174.2.0.31:8000"; //local
// export const host = "34.123.238.119";



export const version = '2.0.2'
// export const url = protocol + host
export const url = protocol + host

// export const OneSignalAppID = '3aa016fb-b951-4c41-9edd-2e246b5187e2'
// export const OneSignalAppID = '3aa016fb-b951-4c41-9edd-2e246b5187e2'
// export const RestAPI = 'YWJlOWRjMGQtMzcyZi00ZTRiLWE0MzUtMTM2ODk3YWZjYWM4'
export const googleClientId = '610876321489-6kv5r7a4pjdsceu9tc89ndshrn4cd6fu.apps.googleusercontent.com'
// export const googleClientId = '984952544904-434eh1ki491qjqdq6o6g30o2fh7u1i5g.apps.googleusercontent.com'


