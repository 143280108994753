import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../Assets/jobin-international-logo.png'
import { version } from '../../Functionalities/Config'
import { SocialMedias } from '../../Views/Contact/ContactUs'
import { services } from '../../Views/Home/Services'
import { navItems } from '../Header/Header'

const Footer = () => {

    const { pathname } = useLocation()


    if (!pathname.includes('/sign')) {

        return (
            <footer className=" bg-white pt-10 shadow-xl">
                <div className='container xl:px-20 px-5 pb-10 m-auto'>

                    <div className='grid lg:grid-cols-4 md:grid-cols-3'>

                        <div className='hidden lg:block'>
                            <img src={Logo} className='h-14' alt="" />
                        </div>


                        <div >
                            <div className='flex flex-col'>
                                <h2 className='font-bold text-xl md:mb-4 py-4 md:py-0'>Quick Link</h2>
                                {navItems.map(item => (
                                    <Link className='my-2 text-gray-400 font-medium text-sm w-min hover:text-gray-600' to={item.url}>{item.title}</Link>
                                ))}
                                <Link className='my-2 text-gray-400 font-medium text-sm min-w-min hover:text-gray-600' to={'/jobs-in-kuwait'}>Find a job</Link>
                                <a className='my-2 text-gray-400 font-medium text-sm min-w-min hover:text-gray-600' href={'/privacy-policy.html'}>Privacy Policy</a>

                            </div>
                        </div>


                        <div >
                            <div className='flex flex-col'>
                                <h2 className='font-bold text-xl md:mb-4 py-4 md:py-0'>Our Service</h2>
                                {services.filter((itm, idx) => idx < 6).map(item => (
                                    <Link className='my-2 text-gray-400 font-medium text-sm w-full hover:text-gray-600' to={'/service'}>{item.title}</Link>
                                ))}
                            </div>
                        </div >


                        <div >

                            <div className='flex flex-col'>
                                <h2 className='font-bold text-xl md:mb-4 py-4 md:py-0'>Contact</h2>

                                <a href='tel:+96591100671' className='my-2 text-gray-400 font-medium text-sm w-full hover:text-gray-600' >+965 9110 0671</a>
                                <a href='mailto:info@job-in-arabia.com' className='my-2 text-gray-400 font-medium text-sm w-full hover:text-gray-600' >careers@job-in-arabia.com</a>
                                <a className='my-2 text-gray-400 font-medium text-sm w-full hover:text-gray-600 w-60' >
                                    Fahaheel, Block 7, Street 67 Fahaheel Khan Building Floor No.1, Office No.10, Kuwait
                                </a>
                                <div className='flex md:justify-start '>
                                    {SocialMedias.map(item => (
                                        // <p>{item.title}</p>
                                        <button className='md:mr-5 mx-2   transition-all duration-500 ease-in  hover:text-gray-600 text-gray-400  rounded-lg w-10 h-10 flex items-center justify-center'>
                                            <a href={item.url} target={'_blank'}>
                                                {item.icon}
                                            </a>
                                        </button>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>

                </div>



                <div className='m-auto  border-t md:h-10 flex md:flex-row flex-col-reverse items-center justify-between container xl:px-20 px-5'>
                    <p className='text-gray-500 font-medium text-sm'>© 2022  Job In Arabia. All rights reserved.</p>
                    <div className='text-gray-500 font-medium text-sm flex'>
                        <p>Powered by</p>
                        <a href="https://jicitsolution.com/" target={'_blank'} className='ml-1 text-blue-500 font-semibold  transition delay-200 hover:underline ease'>JIC IT Solutions</a>
                    </div>
                    <div>
                        <div className='flex md:w-60 md:justify-end '>
                            <div className="text-center text-xs pb-3">
                                {`version ${version}`}
                            </div>
                        </div>
                    </div>
                </div>



            </footer>

        )
    }
}

export default Footer