import React from 'react'

const Card = ({ children, ref }) => {
    return (
        <div ref={ref} className='bg-white p-5 md:rounded-xl rounded-lg shadow-md mt-5'>
            {children}
        </div>
    )
}

export default Card