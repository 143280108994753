import { Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Context } from '../../Common/Context/Context'
import CustomModal from '../Modal/Modal'

const SideMenu = () => {
    const { IsSidemenuOpen, setIsSidemenuOpen, UserData, Token } = useContext(Context)
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [modalOpen, setmodalOpen] = useState(false)

    const Menu = [
        // { title: 'Find jobs', icon: <i class="fas fa-briefcase  text-sm  "></i>, url: '/' },
        { title: 'Home', icon: <i class="fas fa-home  text-sm  "></i>, url: '/' },
        { title: 'About', icon: < i class="fas fa-landmark text-sm  " ></i>, url: '/about' },
        { title: 'Projects', icon: <i class="fas fa-drafting-compass"></i>, url: '/projects' },
        // { title: 'Career', icon: <i class="fas fa-briefcase  text-sm  "></i>, url: '/career' },
        { title: 'Contact', icon: <i class="fas fa-paper-plane  text-sm  "></i>, url: '/contact' },
        { title: 'My Profile', icon: <i class="fas fa-address-card text-sm  "></i>, url: '/career/profile' },

    ]

    useEffect(() => {
        setIsSidemenuOpen(false)
    }, [pathname])

    const CloseModal = () => {
        setmodalOpen(false)
    }
    const handleLogout = () => {
        window.location.replace("/signin");
        localStorage.removeItem('job-in-arabia-userdetails')
        localStorage.removeItem('job-in-arabia-token')
        localStorage.removeItem('job-in-arabia-userdetails')
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        }

    }
    return (
        <main className={`w-72 p-4 h-screen lg:hidden flex flex-col justify-start right-0 z-50 top-0 bg-white fixed transition-all duration-500 ease-in-out ${IsSidemenuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
            <div >
                <div className={`flex ${Token ? 'justify-between' : 'justify-end'}`}>
                    {Token &&
                        <div className='flex'>
                            <button className='w-10 h-10 flex justify-center items-center  bg-slate-100 rounded-full mr-2' >
                                <i class="fas fa-user"></i>
                            </button>
                            <div>

                                <h1 className='text-sm font-semibold'>{`${UserData.first_name} ${UserData.middle_name ? UserData.middle_name : ''} ${UserData.last_name}`}</h1>
                                <h3 className='text-xs font-medium text-gray-500'>{UserData.email}</h3>
                            </div>
                        </div>
                    }

                    <button onClick={() => setIsSidemenuOpen(false)} className='w-10 h-10 flex justify-center items-center  bg-slate-100 rounded-full mr-2'>
                        <i class="fas fa-times"></i>

                    </button>
                </div>

                <div className='mt-10'>
                    <div className="grid grid-cols-1 gap-5">
                        {Menu.filter(itm => !Token ? itm.title != ['My Profile'] : itm).map(item => (
                            <Link to={item.url} className='flex text-black hover:bg-slate-100  px-4 py-2 transition-all duration-500 ease-in rounded'>{item.icon}
                                <p className='ml-2 font-semibold text-sm '>{item.title}</p>
                            </Link>
                        ))}
                        {Token &&
                            <Link to={'/career/jobs'} className='flex text-black hover:bg-slate-100  px-4 py-2 transition-all duration-500 ease-in rounded'>
                                <i class="fas fa-briefcase  text-sm  "></i>
                                <p className='ml-2 font-semibold text-sm '>{'My Jobs'}</p>
                            </Link>
                        }
                    </div>
                </div>
            </div>


            <div className=''>
                {Token ?
                    <button className='px-4 py-2  font-semibold text-sm bg-slate-100 w-full' onClick={() => { setIsSidemenuOpen(false); setmodalOpen(true); }}>
                        <i class="fas fa-sign-out-alt mr-2"></i>
                        Logout</button>
                    :
                    <button onClick={() => navigate('/signin')} className='px-4 py-2  font-semibold text-sm bg-slate-100 w-full'>Sign in / Register</button>
                }
            </div>

            {modalOpen &&
                <CustomModal open={modalOpen} onClose={CloseModal} >
                    <div>
                        <p className=' font-medium'>Are you sure want to logout ?</p>
                    </div>
                    <div className='flex justify-end mt-5'>
                        <div className='flex'>
                            <Button color='inherit' onClick={() => setmodalOpen(false)}>Cancel</Button>
                            <div className='px-1' />
                            <Button variant='contained' onClick={handleLogout}>Yes</Button>
                        </div>
                    </div>

                </CustomModal>
            }
        </main>
    )
}

export default SideMenu