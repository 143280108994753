import Slider from "react-slick";
import React from 'react'
import TestimonialCard from '../../Components/Cards/TestimonialCard'
import SectionButton from '../../Components/SectionButton/SectionButton'
import TextReveal from "../../Components/TextAnimation/TextReveal";
import Slide from 'react-reveal/Slide';

const Testimonial = () => {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        // autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // initialSlide: 2
                }
            }
        ]
    };

    const Testimonial = [
        {
            message: 'If you think Human Resource is an important function, but don’t know how, or don’t have time to do a good job of it, just get Job In Arabia to do it for you. Everyone will think you have suddenly become an HR genius and your staff will absolutely love you for it.',
            author: 'Temsan Construction and Machinery Industry'
        },
        {
            message: 'As we have grown our business, Job In Arabia Human Resource Development Company has proved to be an invaluable resource for support and guidance in all human resource affairs. Like so many businesses, hiring and developing talent is the major component of our success, so creating a framework to support this growth has been essential',
            author: 'Struts Building Technology'
        },
        {
            message: `I can't thank Job In Arabia enough for helping me secure my current role. They are professionals and are very good at determining where a person's skill set is best served. Whenever I had the pleasure of speaking with their team, they were always very understanding and gave me confidence that they would find me the right role. The human resource company supported me throughout the whole process and even after I secured a role`,
            author: 'Jeril john'
        },

    ]

    return (
        <section className='py-10 md:pb-24 md:pt-0 lg:py-28 grid lg:grid-cols-3 grid-cols-1 gap-5'>
            <div>
                <SectionButton ButtonTitle={'Testimonials'} />

                <div className="w-72 my-4">
                    <TextReveal tag={'h2'} tagClassName={'font-black text-2xl'} content={'Trust, Salute '} />
                    <TextReveal tag={'h2'} tagClassName={'font-black text-2xl'} content={'& Acknowledgement '} />
                </div>

                {/* <h2 className='font-black text-2xl my-4 w-48'>See what they says about us</h2> */}
                <p className='text-sm text-gray-500 font-semibold xl:w-96 '>Let’s revisit how our faith reformed the fortune together…</p>
            </div>

            <div className="col-span-2">
                <Slider {...settings}>

                    {Testimonial.map(item => (
                        <Slide bottom>
                            <TestimonialCard message={item.message} author={item.author} />
                        </Slide>
                    ))}


                </Slider>
            </div>
        </section>

    )
}

export default Testimonial