import { Delete, Edit } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import React, { useState } from 'react'
import CustomModal from '../Modal/Modal'

const EducationCard = ({ level, university, startDate, endDate, onEdit, onDelete, isEditable }) => {
    const [MOdalOpen, setMOdalOpen] = useState(false)
    return (
        <>
            <div className='flex justify-between items-center mt-5'>
                <div>
                    <h2 className='font-semibold text-sm'>{level}</h2>
                    <span className='font-light text-xs'>{university}</span>
                </div>

                {(startDate || endDate) &&
                    <div className='flex w-72 justify-end sm:justify-between items-center'>

                        <div className='hidden sm:flex justify-start'>
                            <p className='text-sm'>{startDate}</p>
                            <p className='mx-1 text-sm'>-</p>
                            <p className='text-sm'>{endDate}</p>
                        </div>

                        {isEditable &&
                            <div className="flex">
                                <IconButton aria-label="edit" size="medium" color='inherit' onClick={onEdit} >
                                    <Edit fontSize="inherit" />
                                </IconButton>
                                <IconButton aria-label="delete" size="medium" color='error' onClick={() => setMOdalOpen(true)} >
                                    <Delete fontSize="inherit" />
                                </IconButton>
                            </div>
                        }



                    </div>
                }
            </div>

            {MOdalOpen &&
                <CustomModal open={MOdalOpen} onClose={() => setMOdalOpen(false)} >
                    <div >
                        <p className='font-medium'>
                            Are you sure want to delete ?
                        </p>
                        <div className='flex justify-end mt-5'>
                            <Button color='inherit' onClick={() => setMOdalOpen(false)}>Cancel</Button>
                            <Button color='primary' variant='contained' onClick={onDelete}>Yes</Button>
                        </div>
                    </div>
                </CustomModal>
            }
        </>
    )
}

export default EducationCard