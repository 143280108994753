import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';




firebase.initializeApp({
    apiKey: "AIzaSyAlHkNc-oS6mtZ0dv9j-8Y94CKXmZ6oFdo",
    authDomain: "jobin-hrd.firebaseapp.com",
    projectId: "jobin-hrd",
    storageBucket: "jobin-hrd.appspot.com",
    messagingSenderId: "753717966506",
    appId: "1:753717966506:web:a14cc6bd9b5c9c549dc2d3",
    measurementId: "G-HDHBSHZDR2"
});


export const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider()
export const signInWithGoogle = () => {
    return auth.signInWithPopup(googleProvider)
}
const Facebookprovider = new firebase.auth.FacebookAuthProvider
export const signInWithFacebook = () => {
    return auth.signInWithPopup(Facebookprovider)
}