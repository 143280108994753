import { Send } from '@mui/icons-material'
import { Button, TextField } from '@mui/material'
import React from 'react'
// import TextField from '../../Components/InputField/TextField'
import SectionButton from '../../Components/SectionButton/SectionButton'
import TextReveal from '../../Components/TextAnimation/TextReveal'
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet'

const ContactUs = () => {

    return (

        <main className=' py-20 min-h-screen  container xl:px-20 px-5  relative m-auto'>
            <Helmet>
                <title>Job In Arabia | Contact</title>
                <link rel="canonical" href="https://job-in-arabia.com/contact" />
                
            </Helmet>
            <section className='flex flex-col md:grid md:grid-cols-2 grid-cols-1'>
                <div className='md:border-r-2  '>
                    <TextReveal tag={'h2'} tagClassName={'font-black text-2xl my-2'} content={'Contact Us'} />
                    {/* <h2 className='font-black text-2xl my-2'>Contact Us</h2> */}
                    <TextReveal tag={'p'} tagClassName={'text-sm text-gray-500 md:w-3/4  text-sm text-gray-500 font-semibold'} content={'Say hi to the team'} />
                    {/* <p className='text-sm text-gray-500 md:w-3/4  text-sm text-gray-500 font-semibold'></p> */}
                    {/* <p className='text-sm text-gray-500 md:w-3/4  text-sm text-gray-500 font-semibold'>Say hi to the team</p> */}

                    <Fade>
                        <div className='mt-14 grid grid-cols-1 gap-10 w-full md:w-3/4'>
                            <TextField variant='standard' label="Name" />
                            <TextField variant='standard' label="Email" />
                            <TextField variant='standard' label="Tell us all about it" />

                            <div>
                                <Button color='primary' variant='contained' endIcon={<Send />}>Send</Button>
                            </div>
                            {/* <button className='w-20 rounded bg-primary py-2'>Send</button> */}
                        </div>
                    </Fade>

                </div>
                <div className='flex flex-col justify-evenly py-5 md:p-5 h-96'>
                    <div>
                        <TextReveal tag={'h4'} tagClassName={'font-bold '} content={'Address'} />
                        <TextReveal tag={'p'} tagClassName={'text-gray-500 text-sm font-semibold w-60 mt-3'} content={`
                        Fahaheel, Block 7, Street 67 Fahaheel Khan Building Floor No.1, Office No.10, Kuwait`} />

                    </div>
                    <div className='flex flex-col '>
                        <TextReveal tag={'h4'} tagClassName={'font-bold '} content={'Support'} />
                        <TextReveal tag={'p'} tagClassName={'text-gray-500 mt-3 text-sm font-semibold w-60'} content={'info@job-in-arabia.com'} />
                        <TextReveal tag={'p'} tagClassName={'text-gray-500 mt-3 text-sm font-semibold w-60'} content={'+965 9110 0671'} />

                        {/* <a href='mailto:info@job-in-arabia.com' className='text-gray-500 mt-3 text-sm font-semibold w-60'>info@job-in-arabia.com</a>
                        <a href='tel:+96592212181' className='text-gray-500 mt-3 text-sm font-semibold w-60'>+965 92212181</a> */}
                    </div>

                    <div className='flex w-60 '>
                        {SocialMedias.map(item => (
                            // <p>{item.title}</p>
                            <Fade>

                                <button
                                    className='mr-7 bg-white shadow transition-all duration-500 ease-in hover:bg-black hover:text-white hover:shadow-xl rounded-lg w-10 h-10 flex items-center justify-center'>

                                    <a href={item.url}>
                                        {item.icon}
                                    </a>
                                </button>
                            </Fade>
                        ))}
                    </div>

                </div>
            </section>
        </main>
    )
}

export default ContactUs
export const SocialMedias = [
    { icon: <i className='fab fa-facebook-f' />, title: 'facebook', url: 'https://www.facebook.com/Jobinarabia' },
    { icon: <i className='fab fa-instagram' />, title: 'instagram', url: 'https://www.instagram.com/jobinarabia/' },
    { icon: <i className='fab fa-linkedin-in' />, title: 'linkedin', url: 'https://www.linkedin.com/company/job-in-arabia/' },
]