import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  width:'100%',
  bgcolor: 'background.paper',
  borderRadius:'0.75rem' ,
  border:'none',
  outline:'none',
  boxShadow: 24,
  p: 4,
};

 const CustomModal = ({open, onClose, children})=> {
  return (
    <div>
      
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {children}
        </Box>
      </Modal>
    </div>
  );
}
export default CustomModal