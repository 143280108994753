import React from 'react'
import './Fadeintextwithbar.css'


const FadeInTextWithBar = ({ title, description, titleClass, descClass, descbg, titlebg, titleWidth }) => {
    return (
        <div>
            <div className="box">
                <div className={"title " + titleWidth}>
                    <span className={"block " + titlebg} />
                    <h1 className={titleClass}>{title}<span /></h1>
                </div>
                <div className="role">
                    <div className={"block " + descbg} />
                    <div className={descClass}>{description}</div>
                </div>
            </div>

        </div>
    )
}

export default FadeInTextWithBar