import React from 'react'
import Services from '../Home/Services'

const Service = () => {
    return (
        <main className=' bg-slate-50 xl:px-20 px-5 py-0 relative'>
            <section className='container m-auto'>
                <Services />
            </section>

        </main>
    )
}

export default Service