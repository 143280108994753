import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import TextField from '../../Components/InputField/TextField'
import Gif from '../../Assets/At the office.gif'
import { url } from '../../Functionalities/Config'
import { userValidation } from '../../Functionalities/Validation'
import axios from 'axios'
import { ConvertException, lettersOnly, PreventNonNumeric, ShowFailedMessage, ShowSuccessMessage, validateEmail } from '../../Functionalities/Functions'
// import { LinkedIn } from 'react-linkedin-oauth2';
import { Context } from '../../Common/Context/Context'
import { signInWithFacebook, signInWithGoogle } from '../../Common/Service/Firebase'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import PhoneInput from "react-phone-input-2";
import { LoadingButton } from '@mui/lab'
import { Send } from '@mui/icons-material'


const InitialValue = {
    first_name: '',
    middle_name: '',
    last_name: '',
    date_of_birth: '', email: '',
    username: '',
    password: '',
    mobile: '',
    country: ''
}
const Signup = () => {
    const { setToken, setUserData } = useContext(Context)

    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(1)
    const [data, setdata] = useState(InitialValue)


    const [LinkedinData, setLinkedinData] = useState({
        authCode: '',
        clientId: '77g6a3xr7hut6g',
        secretCode: 'fe9uXBgDsz5cNwFa',
        token: '',
    })
    const [errorMessage, setErrorMessage] = React.useState("");
    const { linkedInLogin } = useLinkedIn({
        clientId: '77g6a3xr7hut6g',
        redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        onSuccess: (code) => {
            console.log(code);
            setLinkedinData({ ...LinkedinData, authCode: code });
            if (code) {
                GetLinkedinToken(code)
            }
        },
        scope: "r_emailaddress r_liteprofile",
        onError: (error) => {
            console.log(error);
        },
    });


    const GetLinkedinToken = (authCode) => {

        var config = {
            method: 'post',
            url: `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code=${authCode}&client_id=${LinkedinData.clientId}&client_secret=${LinkedinData.secretCode}&redirect_uri=${window.location.origin}/linkedin`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Access-Control-Allow-Origin": "*",
                // 'Access-Control-Allow-Credentials': 'true'
            }

        };
        console.log(config)
        axios(config)
            .then(function (res) {
                console.log(JSON.stringify(res.data));
                setLinkedinData({ ...LinkedinData, token: res.data.access_token })

                if (res.data.access_token) {
                    GetLinkedinUserDetails()
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const GetLinkedinUserDetails = () => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: 'https://api.linkedin.com/v2/me',
            headers: {
                'Authorization': `Bearer ${LinkedinData.token}`,
                'cache-control': 'no-cache',
                'Access-Control-Allow-Origin': '*'
            }
        };

        axios(config)
            .then(function (res) {
                console.log(res.data)
                signinWithSocialMedia('linkedin', res.data)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const handleChange = (e) => {
        if ([e.target.type] == 'radio') {
            setdata({ ...data, [e.target.name]: e.target.id })

        }
         else {

            setdata({ ...data, [e.target.name]: e.target.value })
        }
        console.log(data)
    }

    // let ValidatingData = ['username', 'password', 'email', 'mobile']

    const handleSubmit = () => {

        let ValidatingData = ['username', 'email', 'mobile', 'password']
        if (pathname == '/signin') {
            handleLogin()
        } else {
            if (activeTab == 1) {
                let validation = userValidation(data, ValidatingData);
                let emailValidation = validateEmail(data.email)
                if (!emailValidation) {
                    ShowFailedMessage('Please enter a valid email')
                    return
                }

                if (!validation) {
                    return
                }
                setActiveTab(2)
            } else {
                handleSignup()


            }
        }

    }

    const handleLogin = () => {

        let validation = userValidation(data, ['username', 'password']);

        if (!validation) {
            return
        }


        var fdata = new FormData();
        fdata.append('username', data.username);
        fdata.append('password', data.password);

        var config = {
            method: 'post',
            url: url + '/user/login/',
            data: fdata
        };

        axios(config)
            .then(function (res) {
                console.log(res.data)
                if (res.data.Status) {
                    if (res.data.Data.role >= 2) {
                        ShowSuccessMessage(res.data.Message)
                        setToken(res.data.Data.token)
                        setUserData(res.data.Data)
                        localStorage.setItem('job-in-arabia-token', res.data.Data.token);
                        localStorage.setItem('job-in-arabia-userdetails', JSON.stringify(res.data.Data));
                        navigate('/jobs-in-kuwait')
                    }

                    else {
                        ShowFailedMessage('Unauthorised user, you dont have permission to access this')
                    }

                } else if (res.data.Message.includes('Error occured')) {
                    ConvertException(res.data.Message)
                }
                else {
                    ShowFailedMessage(res.data.Message)

                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }



    const handleSignup = () => {


        let ValidatingData = ['first_name', 'last_name', 'date_of_birth']

        let validation = userValidation(data, ValidatingData);
        // let emailValidation = validateEmail(data.email)
        // if (!emailValidation) {
        //     ShowFailedMessage('Please enter a valid email')
        //     return
        // }

        if (!validation) {
            return
        }




        var formData = new FormData();
        formData.append('first_name', data.first_name)
        formData.append('last_name', data.last_name)
        data.middle_name && formData.append('middle_name', data.middle_name)
        formData.append('username', data.username)
        formData.append('password', data.password)
        formData.append('email', data.email)
        formData.append('mobile', data.mobile)
        formData.append('date_of_birth', data.date_of_birth)
        formData.append('country', data.country)

        var config = {
            method: 'post',
            url: url + '/user/register/',
            data: formData
        };

        axios(config)
            .then(function (res) {
                console.log(res.data)
                if (res.data.Status) {
                    ShowSuccessMessage(res.data.Message)
                    setToken(res.data.Data.token)
                    setUserData(res.data.Data)
                    localStorage.setItem('job-in-arabia-token', res.data.Data.token);
                    localStorage.setItem('job-in-arabia-userdetails', JSON.stringify(res.data.Data));
                    navigate('/jobs-in-kuwait')
                } else if (res.data.Message.includes('Error occured')) {
                    ConvertException(res.data.Message)
                }
                else {
                    ShowFailedMessage(res.data.Message)
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const GoogleSignin = async () => {
        let result = signInWithGoogle()
        result.then((res) => {
            console.log(res)
            const userDetails = res.additionalUserInfo.profile

            if (userDetails) {
                signinWithSocialMedia('email', userDetails)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const signinFacebook = () => {
        let result = signInWithFacebook()
        result.then((res) => {
            console.log('fb res----', res)
            const userDetails = res
            if (userDetails) {
                signinWithSocialMedia('facebook', userDetails)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }
    const signinWithSocialMedia = (type, data) => {
        // return console.log(data)
        var formData = new FormData();
        if (type.includes('email')) {
            formData.append('keyword', type)
            formData.append('first_name', data.given_name)
            formData.append('last_name', data.family_name)
            formData.append('google_id', data.id)
            formData.append('username', data.id)
            // formData.append('image', data.picture)
            formData.append('email', data.email)
            formData.append('mobile', data.id)
            formData.append('password', data.id)

        } else if (type.includes('facebook')) {

            formData.append('keyword', type)
            formData.append('first_name', data.additionalUserInfo.profile.first_name)
            formData.append('last_name', data.additionalUserInfo.profile.middle_name + ' ' + data.additionalUserInfo.profile.last_name)
            formData.append('facebook_id', data.additionalUserInfo.profile.id)
            formData.append('username', data.additionalUserInfo.profile.id)
            // formData.append('image', data.picture)
            formData.append('email', data.user.multiFactor.user.email ? data.email : data.additionalUserInfo.profile.id)
            formData.append('mobile', data.user.multiFactor.user.phoneNumber ? data.phoneNumber : data.additionalUserInfo.profile.id)
            formData.append('password', data.additionalUserInfo.profile.id)
        }
        else if (type.includes('linkedin')) {

            formData.append('keyword', type)
            formData.append('first_name', data.firstName.localized.en_US)
            formData.append('last_name', data.lastName.localized.en_US)
            formData.append('linkedin_id', data.id)
            formData.append('username', data.id)
            // formData.append('image', data.picture)
            formData.append('email', data.id)
            formData.append('mobile', data.id)
            formData.append('password', data.id)
        }

        var config = {
            method: 'post',
            url: url + '/user/register/',
            data: formData
        };

        axios(config)
            .then(function (res) {
                console.log(res.data)
                if (res.data.Status) {
                    setUserData(res.data.Data)
                    ShowSuccessMessage(res.data.Message)
                    setToken(res.data.Data.token)
                    localStorage.setItem('job-in-arabia-token', res.data.Data.token);
                    localStorage.setItem('job-in-arabia-userdetails', JSON.stringify(res.data.Data));
                    navigate('/jobs-in-kuwait')
                } else if (res.data.Message.includes('Error occured')) {
                    ConvertException(res.data.Message)
                }
                else {
                    ShowFailedMessage(res.data.Message)
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }



    return (
        <main className='grid md:grid-cols-2 min-h-screen'>
            <section className='flex flex-col lg:p-16 sm:p-12 p-10  '>

                <div className='absolute md:right-5 md:left-auto bottom-5 right-0 left-0 text-center md:text-left '>
                    <Link to={'/'} className={'text-blue-500 text-xs font-medium border-b border-blue-500'}>Back to home</Link>
                </div>
                {/* <section className='flex flex-col lg:p-16 sm:p-12 p-10  '> */}
                <div>
                    <h1 className='capitalize lg:text-5xl md:text-3xl text-xl font-extrabold '>{pathname == '/signup' ? 'sign up' : 'sign in'}</h1>
                </div>
                <div className='sm:mt-10 mt-5  flex items-center'>
                    <div className='w-16 h-0.5 bg-blue-500'></div>
                    <h4 className='font-medium  text-sm ml-5'>{pathname == '/signup' ? 'Sign up' : 'Sign in'} with</h4>
                </div>
                <div className='mt-5 flex items-center w-28 justify-between'>

                    <button onClick={GoogleSignin} type='button' className='bg-white shadow transition-all duration-500 ease-in hover:bg-black hover:text-white hover:shadow-xl rounded-lg w-10 h-10 flex items-center justify-center'>
                        <i class="fab fa-google"></i>
                    </button>






                    <button type='button' onClick={signinFacebook} className='bg-white shadow transition-all duration-500 ease-in hover:bg-black hover:text-white hover:shadow-xl rounded-lg w-10 h-10 flex items-center justify-center'>
                        <i class="fab fa-facebook-f"></i>
                    </button>

                    {/* <button type='button' onClick={linkedInLogin} className='bg-white shadow transition-all duration-500 ease-in hover:bg-black hover:text-white hover:shadow-xl rounded-lg w-10 h-10 flex items-center justify-center'>
                        <i class="fab fa-linkedin-in"></i>
                    </button> */}




                </div>

                {pathname == "/signup" ?
                    <div className='mt-10 grid sm:grid-cols-2 sm:gap-6 md:gap-3 md:grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-6'>
                        {activeTab == 1 ?
                            <>
                                <TextField isRequired={true} onchange={handleChange} value={data.username} name={'username'} type={'text'} label={'username'} />
                                <TextField isRequired={true} onchange={handleChange} value={data.email} name={'email'} type={'mail'} label={'email'} />
                                <TextField isRequired={true} onchange={(phone, e) => setdata({ ...data, mobile: phone, country: e.name })} value={data.mobile} name={'mobile'} onKeyDown={PreventNonNumeric} type={'number'} label={'mobile'} />


                                <TextField isRequired={true} onchange={handleChange} value={data.password} name={'password'} type={'password'} label={'password'} />
                            </>
                            :
                            activeTab == 2 &&
                            <>
                                <TextField isRequired={true} onchange={handleChange} value={lettersOnly(data.first_name)} name={'first_name'} type={'text'} label={'first name'} />
                                <TextField onchange={handleChange} value={lettersOnly(data.middle_name)} name={'middle_name'} type={'text'} label={'middle name'} />
                                <TextField isRequired={true} onchange={handleChange} value={lettersOnly(data.last_name)} name={'last_name'} type={'text'} label={'last name'} />
                                <TextField isRequired={true} onchange={handleChange} value={data.date_of_birth} name={'date_of_birth'} type={'date'} label={'Date Of Birth'} />
                            </>
                        }

                    </div>
                    :
                    <div className='mt-10 grid md:grid-cols-1 sm:grid-cols-2 grid-cols-1 lg:grid-cols-2 gap-6'>
                        <TextField isRequired={true} onchange={handleChange} value={data.username} name={'username'} type={'text'} label={'username'} />
                        <TextField isRequired={true} onchange={handleChange} value={data.password} name={'password'} type={'password'} label={'password'} />

                        <div></div>
                        <div className='flex justify-end'>
                            <button className='text-xs text-blue-500 font-semibold' onClick={() => navigate('/reset-password')} >
                                {'Forgot Password?'}</button>
                        </div>


                    </div>

                }


                <div className='mt-5 flex '>
                    {activeTab == 2 &&
                        <button className='py-3 px-4 bg-blue-500 text-black text-sm font-semibold text-white rounded-xl mr-2' onClick={() => setActiveTab(1)}>
                            <i class="fas fa-arrow-left " ></i>
                        </button>
                    }

                    <button className='py-3 px-4 bg-blue-500 text-black text-sm font-semibold text-white rounded-xl ' type='button' onClick={handleSubmit}>
                        <i class="fas fa-arrow-right " ></i>
                    </button>

                </div>
                <div className='flex  font-medium text-sm mt-10 flex-wrap'>
                    <p className='mr-2'>
                        {pathname == "/signup" ? 'Already have an account?' : `Don't have an account?`}
                    </p>
                    {pathname == "/signup" ?
                        <Link to={'/signin'} className={'text-blue-500	'} onClick={() => { setdata(InitialValue); setActiveTab(1) }} >Sign In</Link>
                        :
                        <Link to={'/signup'} className={'text-blue-500	'} onClick={() => { setdata(InitialValue); setActiveTab(1) }} >Sign Up</Link>
                    }
                </div>
            </section>
            <section className='hidden md:flex justify-center items-center'>
                <img src={Gif} alt="" />
            </section>
        </main>
    )
}

export default Signup