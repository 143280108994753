import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'

const TextField = ({ label, placeholder, type, value, onchange, name, rows, cols, disabled, isRequired, onKeyDown }) => {
    const [ShowPassword, setShowPassword] = useState(false)

    return (
        <div>
            <label htmlFor={label} className='capitalize font-medium text-sm ml-1'>{label}
                {isRequired &&
                    <span className='text-red-500 font-medium text-2xl leading-3'>*</span>
                }
            </label>
            {(type != 'textarea' && name != 'mobile')
                ?
                <>
                    {name == 'password' ?
                        <div className='relative'>

                            <input
                                type={ShowPassword ? 'text' : type}

                                disabled={disabled}
                                placeholder={placeholder}
                                value={value}
                                onChange={onchange}
                                name={name}
                                onKeyDown={onKeyDown}
                                className='w-full bg-slate-100 p-2 rounded-md focus:outline-blue-500 disabled:bg-slate-50' />
                            <button
                                className='absolute right-0 -translate-x-1/3 translate-y-1/3'
                                onClick={() => setShowPassword(!ShowPassword)}>
                                {ShowPassword ?
                                    <i class="fas fa-eye-slash"></i>
                                    :
                                    <i class="fas fa-eye"></i>
                                }
                            </button>
                        </div>
                        :

                        <input
                            type={type}
                            disabled={disabled}
                            placeholder={placeholder}
                            value={value}
                            onChange={onchange}
                            name={name}
                            onKeyDown={onKeyDown}
                            onWheel={e => e.target.blur()}
                            className='w-full bg-slate-100 p-2 rounded-md focus:outline-blue-500 disabled:bg-slate-50' />


                    }

                </>
                :
                type == 'textarea' ?
                    <textarea
                        placeholder={placeholder}
                        value={value}
                        onChange={onchange}
                        name={name}
                        className='w-full bg-slate-100 p-2 rounded-md focus:outline-blue-500'
                        rows={rows}
                        cols={cols}
                    />
                    :
                    name == 'mobile' &&
                    <PhoneInput
                        disabled={disabled}
                        inputClass='w-full bg-slate-100 p-2 rounded-md focus:outline-blue-500 disabled:bg-slate-50'
                        country={"kw"}
                        enableSearch={true}
                        value={value}
                        onChange={onchange} />
            }
        </div>
    )
}

export default TextField