import React from 'react'
import Slide from 'react-reveal/Slide';

const SectionButton = ({ ButtonTitle, color }) => {
    return (
        <Slide bottom>
            <button className={`bg-${color ? color : 'violet'}-200 text-${color ? color : 'violet'}-500 font-bold text-sm px-4 py-1 rounded cursor-default`}>
                {ButtonTitle}
            </button>
        </Slide>
    )
}

export default SectionButton