import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import TextField from '../../Components/InputField/TextField'
import Gif from '../../Assets/Forgot password.gif'
import { url } from '../../Functionalities/Config'
import { userValidation } from '../../Functionalities/Validation'
import axios from 'axios'
import { ConvertException, PreventNonNumeric, ShowFailedMessage, ShowSuccessMessage, validateEmail } from '../../Functionalities/Functions'
import { Context } from '../../Common/Context/Context'
import { LoadingButton } from '@mui/lab'
import { Send, Done, Restore } from '@mui/icons-material'



const ResetPassword = () => {
    const { setToken, setUserData } = useContext(Context)

    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [ForgotPasswordData, setForgotPasswordData] = useState(
        { email: '', code: '', password: '', confirmPassword: '' }
    )
    const [emailSend, setemailSend] = useState(false)
    const [emailSendBtnLoading, setemailSendBtnLoading] = useState(false)
    const [CodeVerified, setCodeVerified] = useState(false)
    const [codeVerificationBtnLoading, setcodeVerificationBtnLoading] = useState(false)
    const [ResetBtnLoading, setResetBtnLoading] = useState(false)
    const [codesend, setcodesend] = useState(false)
    const [enableCodeResend, setenableCodeResend] = useState(false)
    const [disableCodeResendBtn, setdisableCodeResendBtn] = useState(false)
    const [counter, setCounter] = useState(29);
    const [forgotPswEnable, setforgotPswEnable] = useState(false)

    React.useEffect(() => {
        const timer =
            disableCodeResendBtn && counter > 0 ?
                setInterval(() => setCounter(counter - 1), 1000)
                :
                setdisableCodeResendBtn(false)



        return () => clearInterval(timer);
    }, [counter]);

    console.log({ counter })

    const SendOTP = () => {

        setemailSendBtnLoading(true)


        let emailValidation = validateEmail(ForgotPasswordData.email)
        if (!emailValidation) {
            ShowFailedMessage('Please enter a valid email')
            setemailSendBtnLoading(false)
            return
        }


        var FormData = require('form-data');
        var formdata = new FormData();
        formdata.append('email', ForgotPasswordData.email);

        var config = {
            method: 'post',
            url: url + '/user/forgot/',
            data: formdata

        };

        axios(config)
            .then(function (res) {
                console.log(res.data);
                setemailSendBtnLoading(false)
                if (res.data.Status) {
                    ShowSuccessMessage(res.data.Message)
                    setemailSend(true)
                } else {
                    ShowFailedMessage(res.data.Message)
                }
            })
            .catch(function (error) {
                console.log(error);
                setemailSendBtnLoading(false)
            });

    }

    const VerifyOTP = () => {
        setcodesend(true)
        setenableCodeResend(true)


        if (ForgotPasswordData.code.length <= 5) {
            ShowFailedMessage('verification code must be 6 digit')
            return
        }
        setcodeVerificationBtnLoading(true)


        var FormData = require('form-data');
        var formdata = new FormData();
        formdata.append('email', ForgotPasswordData.email);
        formdata.append('ver_code', ForgotPasswordData.code);
        formdata.append('keyword', 'validate');

        var config = {
            method: 'post',
            url: url + '/user/forgot/',
            data: formdata

        };

        axios(config)
            .then(function (res) {
                console.log(res.data);
                setcodeVerificationBtnLoading(false)
                if (res.data.Status) {
                    ShowSuccessMessage(res.data.Message)
                    setCodeVerified(true)
                    setenableCodeResend(false)

                } else {
                    ShowFailedMessage(res.data.Message)
                }
            })
            .catch(function (error) {
                console.log(error);
                setcodeVerificationBtnLoading(false)
            });

    }
    const ResetPassword = () => {

        if (!ForgotPasswordData.password) {
            ShowFailedMessage('password cannot be empty')
            return
        }
        if (ForgotPasswordData.password !== ForgotPasswordData.confirmPassword) {
            ShowFailedMessage('passwords does not match')
            return
        }

        setResetBtnLoading(true)



        var FormData = require('form-data');
        var formdata = new FormData();
        formdata.append('keyword', 'change_password');
        formdata.append('email', ForgotPasswordData.email);
        formdata.append('password', ForgotPasswordData.password);


        var config = {
            method: 'post',
            url: url + '/user/forgot/',
            data: formdata

        };

        axios(config)
            .then(function (res) {
                console.log(res.data);
                setResetBtnLoading(false)
                if (res.data.Status) {
                    ShowSuccessMessage(res.data.Message)
                    navigate('/signin')
                } else {
                    ShowFailedMessage(res.data.Message)
                }
            })
            .catch(function (error) {
                console.log(error);
                setResetBtnLoading(false)
            });

    }
    const ResendOTP = () => {

        setdisableCodeResendBtn(true)
        setCounter(29)
        SendOTP()
    }
    return (
        <main className='grid md:grid-cols-2 min-h-screen'>
            <section className='flex flex-col lg:p-16 sm:p-12 p-10  '>

                <div className='absolute md:left-5 md:left-auto bottom-5 right-0 left-0 text-center md:text-left '>
                    <Link to={'/signin'} className={'text-blue-500 text-xs font-medium border-b border-blue-500'}>Back to sign in</Link>
                </div>
                {/* <section className='flex flex-col lg:p-16 sm:p-12 p-10  '> */}
                <div>
                    <h1 className='capitalize lg:text-4xl md:text-3xl text-xl font-extrabold '>{'Reset Password'}</h1>
                </div>




                <div className='mt-10 grid md:grid-cols-1 sm:grid-cols-2 grid-cols-1 lg:grid-cols-2 gap-6'>

                    <>
                        {!CodeVerified ?
                            <>
                                <TextField isRequired={true} onchange={(e) => setForgotPasswordData({ ...ForgotPasswordData, email: e.target.value })} value={ForgotPasswordData.email} name={'email'} type={'mail'} label={'email'} />
                                {emailSend ?
                                    <>
                                        <TextField isRequired={true} onchange={(e) => setForgotPasswordData({ ...ForgotPasswordData, code: e.target.value })} value={ForgotPasswordData.code} name={'code'} type={'mail'} label={'code'} />

                                    </>
                                    :
                                    <div>
                                    </div>
                                }
                            </>
                            :

                            <>
                                <TextField isRequired={true} onchange={(e) => setForgotPasswordData({ ...ForgotPasswordData, password: e.target.value })} value={ForgotPasswordData.password} name={'confirmPassword'} type={'password'} label={'New Password'} />
                                <TextField isRequired={true} onchange={(e) => setForgotPasswordData({ ...ForgotPasswordData, confirmPassword: e.target.value })} value={ForgotPasswordData.confirmPassword} name={'password'} type={'password'} label={'Confirm New Password'} />
                            </>

                        }
                    </>


                    <div className='mt-5 '>
                        {!emailSend
                            ?
                            <LoadingButton
                                size="small"
                                onClick={SendOTP}
                                endIcon={<Send />}
                                loading={emailSendBtnLoading}
                                loadingPosition="end"
                                variant="contained"
                            >Send Code</LoadingButton>
                            :
                            <div >
                                {(!CodeVerified) &&
                                    <LoadingButton
                                        size="small"
                                        onClick={VerifyOTP}
                                        endIcon={<Done />}
                                        loading={codeVerificationBtnLoading}
                                        loadingPosition="end"
                                        variant="contained"
                                    >Verify Code</LoadingButton>
                                }




                            </div>
                        }

                        {CodeVerified &&
                            <LoadingButton
                                size="small"
                                onClick={ResetPassword}
                                endIcon={<Done />}
                                loading={ResetBtnLoading}
                                loadingPosition="end"
                                variant="contained"
                            >Reset Password</LoadingButton>
                        }
                    </div>

                    <div className='sm:mt-5 md:mt-0 lg:mt-5 flex '>
                        {(enableCodeResend) &&

                            <>

                                <LoadingButton
                                    size="small"
                                    disabled={disableCodeResendBtn}
                                    onClick={ResendOTP}
                                    endIcon={<Restore />}
                                    loading={emailSendBtnLoading}
                                    loadingPosition="end"
                                    color='error'
                                // variant="contained"
                                >{disableCodeResendBtn ? `Resend Code in ${counter} seconds` : 'Resend Code'}</LoadingButton>
                            </>
                        }
                    </div>

                </div>
                {(emailSend && !CodeVerified) &&
                    <div className='mt-10'>
                        <p className='text-sm text-gray-600'>
                            Your request for a Password Reset has been approved.
                            A verification code is sent to
                            <span className='font-bold text-gray-700'> {ForgotPasswordData.email}</span>
                            . Please check the mail and enter the code in the given space below.
                        </p>
                    </div>
                }




            </section>
            <section className='hidden md:flex justify-center items-center'>
                <img src={Gif} alt="" />
            </section>
        </main>
    )
}

export default ResetPassword