import React, { useEffect, useState } from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    useLocation,
    useNavigate,
    Navigate,
} from "react-router-dom";
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import { ManageAPIs } from '../../Functionalities/Logic';
import JobDetails from '../../Views/JobsSection/JobDetails';
import Jobs from '../../Views/JobsSection/Jobs';
import JobsSection from '../../Views/JobsSection/JobsSection';
import Login from '../../Views/Login/Login';
import Signup from '../../Views/Signup/SignUp';
import { Context } from '../Context/Context';
import { LinkedInPopUp } from 'react-linkedin-oauth2';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import LinkedInPage from '../../Components/LinkedinLogin/LinkedinLogin';
import AppliedJobs from '../../Views/AppliedJobs/AppliedJobs';
import Home from '../../Views/Home/Home';
import About from '../../Views/About/About';
import Service from '../../Views/Services/Service';
import ContactUs from '../../Views/Contact/ContactUs';
import Project from '../../Views/Projects/Project';
import ScrollTop from './ScrollTop';
import Maintenance from '../../Views/Maintenance/Maintenance';
import ResetPassword from '../../Views/ResetPassword/ResetPassword';

const Router = () => {
    const [IsmodalOpen, setIsmodalOpen] = useState(false)
    const [IsSidemenuOpen, setIsSidemenuOpen] = useState(false)
    const [UserID, setUserID] = useState(0)
    const [UserData, setUserData] = useState({})
    const [Experience, setExperience] = useState([])
    const [Educations, setEducations] = useState([])
    const [Token, setToken] = useState('')

    useEffect(() => {
        let userToken = ''
        if (localStorage.getItem('job-in-arabia-token')) {
            userToken = localStorage.getItem('job-in-arabia-token')

        }
        setToken(userToken)
        let data = {}
        if (JSON.parse(localStorage.getItem('job-in-arabia-userdetails'))) {
            data = JSON.parse(localStorage.getItem('job-in-arabia-userdetails'))
            console.log(data)
            setUserID(data.id)
            setUserData(data)
        }

    }, [])


    useEffect(() => {
        if (Token) {
            GetExperience()
            GetEducations()
        }

    }, [Token])



    const GetExperience = () => {
        let path = '/experience/'

        let result = ManageAPIs('get', path, '', '', Token)
        result.then((res) => {
            console.log('exp res', res)
            if (res.data) {
                setExperience(res.data.results)
            }
        })

            .catch((err) => {
                console.log(err)
            })
    }
    const GetEducations = () => {
        let path = '/educationdetails/'

        let result = ManageAPIs('get', path, '', '', Token)
        result.then((res) => {
            console.log('edu res', res)
            if (res.data.results) {
                setEducations(res.data.results)
            }
        })

            .catch((err) => {
                console.log(err)
            })
    }


    const GetUserData = () => {
        let path = '/user/listUsers/'

        let result = ManageAPIs('get', path, '', '', Token)
        result.then((res) => {
            console.log('USERDATA -------------', res)
            if (res.data?.results[0]) {
                setUserData(res.data.results[0])
                localStorage.setItem('job-in-arabia-userdetails', JSON.stringify(res.data.results[0]))
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }






    return (
        <Context.Provider value={{
            IsmodalOpen, setIsmodalOpen,
            IsSidemenuOpen, setIsSidemenuOpen,
            UserID, Experience, setExperience,
            Educations, setEducations,
            GetEducations, GetExperience,
            UserData, setUserData,
            Token, setToken,
            GetUserData
        }}>

            <BrowserRouter>
                <ScrollTop />
                <Header />


                <Routes>

                    {/* <Route path='/' element={<Maintenance />} /> */}

                    <Route path='/' element={<Home />} />
                    <Route path="/jobs-in-kuwait" element={<Jobs />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/service' element={<Service />} />
                    <Route path='/contact' element={<ContactUs />} />
                    <Route path='/projects' element={<Project />} />

                    <Route path="/career/profile" element={<Jobs />} />

                    <Route path="/jobs-in-kuwait/:slug/job-details/:id" element={<Jobs />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/signin" element={<Signup />} />
                    <Route path="/reset-password" element={<ResetPassword />} />

                    <Route path="/linkedin" element={<LinkedInCallback />} />
                    <Route path="/linkedin-login" element={<LinkedInPage />} />
                    <Route path="/career/jobs" element={<AppliedJobs />} />



                </Routes>

                <Footer />

            </BrowserRouter>

        </Context.Provider>
    )
}

export default Router